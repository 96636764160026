import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import _, { set, template } from "lodash";
import SimpleTable from "components/Table/Simple";
import Textarea from "components/Form/Textarea";
import Button from "components/Form/Button";
// import { memberClient } from "apis/member";
import { doctorClient } from "apis/doctor";
import Card from "components/Card";
import { flattenQuestions, flattenSubmission, reverseTranslate as reverseTrs } from "pages/Admin/Task/TranslateSubmission/Editor";
import { tr } from "utils/translate";
import { submissionColumnInfos, basicUserColumnInfos, additionalUserColumnInfos } from "./config";
import Select from "components/Form/Select";
import VariableWriteForm from "./VariableWriteForm";
import { useQuill } from "react-quilljs";
import Quill from "quill";
import { assembleQuillContentsWithFields } from "pages/Admin/Clinic/PrescriptionTemplate/Editor/helper";
import QuillMadeArticle from "components/Article/QuillMade";
import { toast } from "react-toastify";
import Input from "components/Form/Input";

const parseSubmissionDetails = (questions, submission, intakeTranslations, submissionTranslations) => {
    // const flatQ = intake.sections.flatMap(section => flattenQuestions(section.questions));
    const flatQ = flattenQuestions(questions);
    console.log("flatQ", flatQ);
    const flatA = flattenSubmission(submission);
    console.log("flatA", flatA);
    const tempTableData = flatA.map(a => {
        const { question_id, answer } = a;
        const question = flatQ.find(q => q.question_id === question_id);
        if (_.isNil(question)) {
            return null;
        }
        const reversedIntakeTrs = reverseTrs(intakeTranslations);
        const trs = [...reversedIntakeTrs, ...submissionTranslations];
        const answer_translated = tr(answer, trs);
        return {
            question_id,
            question_idx: question.question_idx,
            question: question.text,
            question_type: question.type,
            answer,
            answer_translated
        }
    }).filter(a => !_.isNil(a));
    return tempTableData;
}

const DoctorPrescriptionWritePage = () => {
    const { submissionId } = useParams();
    const [userTableData, setUserTableData] = useState([]);
    const [clinicUserId, setClinicUserId] = useState("");
    const [userName, setUserName] = useState(null);
    const [submissionTableDataBySection, setSubmissionTableDataBySection] = useState({});
    const [prescriptionTemplateItems, setPrescriptionTemplateItems] = useState([]);
    const [selectedPrescriptionTemplateItem, setSelectedPrescriptionTemplateItem] = useState(null);
    const [prescriptionTemplate, setPrescriptionTemplate] = useState<any>({});
    const [initialFormData, setInitialFormData] = useState({});
    const [currentFields, setCurrentFields] = useState([]);
    const [quill, quillRef] = useState(new Quill(document.createElement('div')));
    const [previewHtml, setPreviewHtml] = useState("");
    const [comment, setComment] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (_.isNil(submissionId) || _.isEmpty(submissionId)) {
            navigate("/doctor/prescription");
        }
        fetchSubmission();
        fetchPrescriptionTemplatesList();
    }, [])

    useEffect(() => {
        fetchPrescriptionDetails();
    }, [prescriptionTemplateItems])

    useEffect(() => {
        if (_.isNil(selectedPrescriptionTemplateItem?.value)) {
            return;
        }
        fetchPrescriptionTemplate(selectedPrescriptionTemplateItem.value);
    }, [selectedPrescriptionTemplateItem])

    const fetchSubmission = async () => {
        const params = {
            lang: "jp",
        }
        const ret = await doctorClient.get(`/public/submissions/${submissionId}/details`, { params });
        const { user, intake, intakeTranslations, submission, submissionTranslations } = ret.data;
        console.log("[fetchSubmission] details", user, intake, intakeTranslations, submission, submissionTranslations);
        setUserName(user.name);
        setUserTableData([_.pick(user, ["name", "gender", "nation", "birthdate", "address", "point", "note", "created_at"])]);
        let tempSubmissionTableDataBySection = {};
        if (_.isNil(intake?.sections)) {
            return;
        }
        intake.sections.forEach((section, idx) => {
            const tempTableData = parseSubmissionDetails(section.questions, submission, intakeTranslations, submissionTranslations);
            tempSubmissionTableDataBySection[idx] = {
                name: section.name,
                data: tempTableData
            }
        });
        // const tempTableData = parseSubmissionDetails(intake.sections[0].questions, submission, intakeTranslations, submissionTranslations);
        // setSubmissionTableData(tempTableData);
        setSubmissionTableDataBySection(tempSubmissionTableDataBySection);
        setComment(submission.comment);
        console.log(ret);
    }

    const fetchPrescriptionTemplatesList = async () => {
        const ret = await doctorClient.get(`/public/prescription-templates/distinct/latest`);
        const tempItems = ret.data.map((elm) => ({ name: `${elm.name} (${elm.description})`, value: elm.prescription_template_id }));
        setPrescriptionTemplateItems(tempItems);
        console.log(ret);
    }

    const fetchPrescriptionDetails = async () => {
        // for load current prescription
        try {
            const ret = await doctorClient.get(`/public/prescriptions/${submissionId}/details/safe`);
            console.log("[fetchPrescriptionDetails] ret", ret)
            const presTemplateId = ret.data.prescription_template_id;
            const tempClinicUserId = ret.data.clinic_user_id;
            setClinicUserId(tempClinicUserId);
            const found = prescriptionTemplateItems.find((item) => item.value === presTemplateId);
            if (_.isNil(found)) {
                throw new Error("템플릿을 찾을 수 없습니다.");
            }
            setSelectedPrescriptionTemplateItem(found);
            setInitialFormData(ret.data.fields.reduce((acc, field) => {
                acc[field.key] = field.data;
                return acc;
            }, {}));
            setCurrentFields(ret.data.fields);
        } catch (e) {
            setSelectedPrescriptionTemplateItem({ name: "템플릿을 선택해주세요", value: "" });
            console.error(e);
        }
    }

    const fetchPrescriptionTemplate = async (prescription_template_id) => {
        const ret = await doctorClient.get(`/prescription-templates/${prescription_template_id}`);
        console.log("[fetchPrescriptionTemplate] ret", ret);
        setPrescriptionTemplate(ret.data);
        return ret.data;
    }

    const onSave = async () => {
        console.log("CURRENT FIELDS", currentFields);
        if (_.isEmpty(clinicUserId)) {
            toast.error("고객번호를 입력해주세요.");
            return false
        }
        if (_.isEmpty(currentFields)) {
            toast.error("처방전을 작성해주세요.");
            return false
        };
        // if one of data field is empty, return
        if (currentFields.some(field => _.isNil(field.data))) {
            toast.warn("모든 처방전 내용을 작성해주세요.");
            return false
        }
        const data = {
            submission_id: submissionId,
            prescription_template_id: prescriptionTemplate.prescription_template_id,
            prescription_template_version: prescriptionTemplate.version,
            prescription_template_name: prescriptionTemplate.name,
            fields: currentFields
        }
        try {
            const presRet = await doctorClient.post(`/prescriptions`, data);
            console.log("pres ret", presRet)
            toast.success("처방전이 작성되었습니다.");
        } catch (e) {
            console.error(e);
            toast.error("처방전 작성에 실패했습니다.");
            return false
        }
        try {
            const body = {
                clinicUserId: clinicUserId
            }
            const patchRet = await doctorClient.patch(`/trackings/${submissionId}/clinic_user_id`, body);
            console.log("patch ret", patchRet);
            // toast.success("고객번호가 저장되었습니다.");
            return true;
        } catch (e) {
            console.error(e);
            // toast.error("고객번호 저장에 실패했습니다.");
            return false;
        }
    }

    const onComplete = async () => {
        const ok = await onSave();
        if (!ok) {
            return;
        }
        try {
            const ret = await doctorClient.patch(`/trackings/${submissionId}/awaiting_prescription_translation`);
            console.log("status ret", ret);
            toast.success("처방전 작성 완료 상태로 변경되었습니다.");
        } catch (e) {
            console.error(e);
            toast.error("처방전 작성 완료 상태로 변경에 실패했습니다.");
            return
        }
        navigate(-1)
    }

    const onTemplateSelected = async (item) => {
        console.log("[DoctorPrescriptionWritePage] onTemplateSelected item", item);
        setInitialFormData({});
        setSelectedPrescriptionTemplateItem(item);
    }

    useEffect(() => {
        console.log("[DoctorPrescriptionWritePage] prescriptionTemplate", prescriptionTemplate)
        if (_.isEmpty(prescriptionTemplate)) {
            return;
        }
        createAndUpdateHtmlPreview(currentFields, prescriptionTemplate.contents);
    }, [prescriptionTemplate])

    const onFormDataChanged = (data) => {
        console.log("[DoctorPrescriptionWritePage] onFormDataChanged data", data);
        if (_.isNil(prescriptionTemplate?.fields)) {
            return;
        }
        const newFields = prescriptionTemplate.fields.map((field) => {
            const { key } = field;
            return {
                ...field,
                data: data[key],
                translated_data: ""
            }
        })
        setCurrentFields(newFields);
        const contents = prescriptionTemplate.contents;
        createAndUpdateHtmlPreview(newFields, contents);
    }

    const createAndUpdateHtmlPreview = (fields, contents) => {
        console.log("[DoctorPrescriptionWritePage] createAndUpdateHtmlPreview fields, contents", fields, contents);
        if (_.isNil(contents)) {
            return;
        }
        const newContents = assembleQuillContentsWithFields(contents, fields, false);
        console.log("[DoctorPrescriptionWritePage] createAndUpdateHtmlPreview newContents", newContents);
        quill.setContents(newContents);
        setPreviewHtml(quill.getSemanticHTML());
    }

    return (
        <Card>
            <div className="p-2">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className="p-2 lg:border-r">
                        <h1 className="text-lg font-semibold">고객정보</h1>
                        <div>
                            <h1 className="ms-1 text-sm font-semibold">기초정보</h1>
                            <div className="px-2">
                                <SimpleTable data={userTableData} columns={basicUserColumnInfos} />
                            </div>
                        </div>
                        <div>
                            <h1 className="ms-1 text-sm font-semibold">추가정보</h1>
                            <div className="px-2">
                                <SimpleTable data={userTableData} columns={additionalUserColumnInfos} />
                            </div>
                        </div>
                        <h1 className="text-lg font-semibold">응답지</h1>
                        {!_.isNil(comment) &&
                            <div className="mb-1">
                                <h1 className="ms-1 text-sm font-semibold">주석</h1>
                                <div className="px-2">
                                    <Textarea value={comment} disabled={true} />
                                </div>
                            </div>
                        }
                        {Object.keys(submissionTableDataBySection).map((sectionIdx) => {
                            return (
                                <div key={sectionIdx}>
                                    <h1 className="ms-1 text-sm font-semibold">{submissionTableDataBySection[sectionIdx].name}</h1>
                                    <div className="px-2">
                                        <SimpleTable data={submissionTableDataBySection[sectionIdx].data} columns={submissionColumnInfos} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="p-2 flex flex-col space-y-1">
                        <h1 className="text-lg font-semibold">고객번호 입력</h1>
                        <div className="px-4">
                            <Input
                                value={clinicUserId}
                                onChange={setClinicUserId}
                                withButton={true}
                                buttonLabel="고객검색"
                                onButtonClicked={() => {
                                    window.open(`/doctor/status?user_search_text=${clinicUserId}`, "_blank")
                                }}
                            />
                        </div>
                        <h1 className="text-lg font-semibold">처방전 작성</h1>
                        <div className="mb-2 px-2">
                            <h1 className="text-sm font-semibold">템플릿</h1>
                            <div className="p-2">
                                <Select items={prescriptionTemplateItems} selectedItem={selectedPrescriptionTemplateItem} onChange={onTemplateSelected} />
                            </div>
                        </div>
                        <div className="mb-2 px-2">
                            <h1 className="text-sm font-semibold">템플릿 입력</h1>
                            <Card>
                                <div className="p-2">
                                    <VariableWriteForm
                                        userName={userName}
                                        initialFormData={initialFormData}
                                        templateFields={prescriptionTemplate.fields}
                                        onDataChanged={onFormDataChanged}
                                    />
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
                <div className="mb-2 px-2">
                    <h1 className="text-lg font-semibold">미리보기</h1>
                    <Card>
                        <div className="p-2">
                            <QuillMadeArticle htmlText={previewHtml}></QuillMadeArticle>
                        </div>
                    </Card>
                </div>
                <div className="flex justify-end space-x-2">
                    <div>
                        <Button onButtonClicked={() => { navigate(-1) }}>뒤로가기</Button>
                    </div>
                    <div>
                        <Button onButtonClicked={onSave}>저장</Button>
                    </div>
                    <div>
                        <Button onButtonClicked={onComplete}>완료</Button>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default DoctorPrescriptionWritePage;
