import React, { useEffect, useRef, useState } from "react";
import Card from "components/Card";
// import CRUDTable from "components/Table/CRUD";
// import SimpleTable from "components/Table/Simple";
import PagingTable, { MultiSelectFilter } from "components/Table/Paging";
import { CustomerState, columnInfos } from "./config";
import _ from "lodash";
import Select from "components/Form/Select";
import MultiSelect from "components/Form/MultiSelect";
import TaskManager from "./TaskManager";
import DetailViewer from "./DetailViewer";
import { Column } from "components/Table/Simple";
import { randomString } from "utils/math";
import { adminClient, AdminClient } from "apis/admin";
import { convertClinicIdColumnSelectable, createRowInfoWithClinic } from "pages/Admin/Clinic/helper";
import IconButton from "components/Form/Button/Icon";
import excelIcon from "assets/logo/excel.png";
import SpinnerMedium from "components/Spinner/Medium";
import { RangeDatePicker } from "components/Form/Datepicker/Range";
import moment from "moment-timezone";
import PageTitle from "components/Title/Page";
import ToggleSearchInput from "components/Form/Input/ToggleSearch";
import BadgeWithRemove from "components/Badges/BadgeWithRemove";
import { useModal } from "components/Modal/Simple";
import PurchaseViewer from "./PurchaseViewer";
import { CalculatorIcon } from "@heroicons/react/24/outline";

const STATE_OPTIONS = Object.keys(CustomerState).map((key) => ({
    name: CustomerState[key],
    value: key,
}))

const STATUS_FILTER: MultiSelectFilter = {
    field: "states",
    label: "상태",
    options: STATE_OPTIONS.filter((elm) => CustomerState[elm.value] !== CustomerState.PURCHASE_FAILURE)
}

export const STICKY_COL_OPTION_LABEL = "고객명 틀고정";

export const TABLE_OPTION_FILTER: MultiSelectFilter = {
    field: "table_options",
    label: "옵션",
    options: [
        { name: STICKY_COL_OPTION_LABEL, value: true },
    ]
}

const AdminCustomerStatusPage = () => {
    const [intakeInfos, setIntakeInfos] = useState(null);
    const [intakeItems, setIntakeItems] = useState(null);
    const [selectedIntakeItem, setSelectedIntakeItem] = useState(null);
    const [selectedStates, setSelectedStates] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const [refreshKey, setRefreshKey] = useState(randomString());
    const [clinicInfos, setClinicInfos] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [filterParams, setFilterParams] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [isStickyFirstCol, setIsStickyFirstCol] = useState(true);
    const { Modal: PurchaseViewerModal, openModal: openPurchaseViewerModal, closeModal: closePurchaseViewerModal } = useModal();

    useEffect(() => {
        console.log("[AdminCustomerStatusPage] useEffect []")
        setIsReady(false);
        initialize();
    }, [])

    useEffect(() => {
        if (_.isNil(intakeInfos)) {
            return;
        }
        const tempIntakeItems = [
            { name: "모든 진료지", value: null },
            null,
            ...intakeInfos.filter((elm) => elm.clinic_id !== "COMMON").map((elm) => ({ name: elm.title, value: elm.intake_id }))
        ]
        setIntakeItems(tempIntakeItems);
        setSelectedIntakeItem(tempIntakeItems[0]);
    }, [intakeInfos])

    useEffect(() => {
        console.log("[useEffect] selectedIntakeItem", selectedIntakeItem)
        console.log("[useEffect] selectedStatusItems", selectedStates)
        console.log("[useEffect] selectedDateRange", selectedDateRange)
        console.log("[useEffect] searchFilter", searchText)
        if (_.isNil(selectedIntakeItem)) {
            console.log("[useEffect] selectedIntakeItem is nil")
            return;
        }
        if (_.isNil(selectedStates)) {
            console.log("[useEffect] selectedStatusItems is nil")
            return;
        }
        if (_.isNil(selectedDateRange)) {
            console.log("[useEffect] selectedDateRange is nil")
            return;
        }

        setFilterParams({
            ...(!_.isNil(searchText) && { search_text: searchText }),
            ...(!_.isNil(selectedIntakeItem.value) && { intake_id: selectedIntakeItem.value }),
            states: selectedStates,
            created_at_start: selectedDateRange[0],
            created_at_end: selectedDateRange[1]
        })
        setRefreshKey(randomString());
    }, [selectedIntakeItem, selectedStates, selectedDateRange, searchText])

    const updateIntakeIds = async () => {
        try {
            const ret = await adminClient.get(`/intakes/distinct/latest`);
            const data = ret.data;
            setIntakeInfos(data);
        } catch (e) {
            console.error(e);
        }
    }

    const initialize = async () => {
        await updateIntakeIds();
        const ret = await AdminClient.getClinicInfos();
        setClinicInfos(ret);
        console.log("[setIsReady] true")
        setIsReady(true);
    }

    const onIntakeFilterChanged = (item) => {
        console.log("[onIntakeFilterChanged] onIntakeFilterChanged", item)
        setSelectedIntakeItem(item);
    }

    const onStatusFilterChanged = (itemNames: string[]) => {
        console.log("[onStatusFilterChanged] itemNames", itemNames);
        const stateValues = itemNames.map((name) => {
            const key = Object.keys(CustomerState).find((key) => CustomerState[key] === name);
            return key;
        })
        setSelectedStates(stateValues);
    }

    const onSubmissionDateRangeChanged = (range) => {
        console.log("[onSubmissionDateRangeChanged] range", range)
        if (range.some((elm) => _.isNil(elm))) {
            console.log("[useEffect] some of range has nil")
            return;
        }
        setSelectedDateRange([moment(range[0]).format("YYYY-MM-DD"), moment(range[1]).format("YYYY-MM-DD")]);
    }

    const onTableOptionFilterChanged = (itemNames: string[]) => {
        console.log("[onTableOptionFilterChanged] itemNames", itemNames)
        const tempSticky = itemNames.includes(STICKY_COL_OPTION_LABEL);
        setIsStickyFirstCol(tempSticky);
        // setRefreshKey(randomString());
    }

    const fetchData = async (page: number, limit: number) => {
        console.log("[fetchData] page", page, "limit", limit, "filterParams", filterParams);
        if (_.isNil(filterParams)) {
            return {
                data: [],
                page: 1,
                total: 0
            }
        }
        if (_.isEmpty(filterParams.states)) {
            return {
                data: [],
                page: 1,
                total: 0
            }
        }
        const params = {
            page,
            limit,
            ...filterParams
        }
        const ret = await adminClient.get("/trackings", { params });
        return ret.data;
    }

    const rowInfos = createRowInfoWithClinic(clinicInfos);
    const columnInfosWithTasks: Column[] = [
        {
            field: "customer_uid",
            label: "고객명",
            visible: isStickyFirstCol,
            render: (value, field, row) => {
                const style = rowInfos?.rowStyle(row);
                const bgColor = style?.backgroundColor;
                return (
                    <div className="flex space-x-1">
                        <div className="">{row.user_name}</div>
                        {row.clinic_user_id &&
                            <BadgeWithRemove label={row.clinic_user_id} isRemovable={false} bgColor={bgColor ?? ""}></BadgeWithRemove>
                        }
                    </div>)
            }
        },
        {
            field: "tasks",
            label: "작업",
            visible: true,
            render: (value, field, row) => <TaskManager value={value} row={row} onTaskFinished={() => { setRefreshKey(randomString()) }} />,
            align: "center"
        },
        {
            field: "clinic_detail",
            label: "진료내용",
            visible: true,
            render: (value, field, row) => (
                <div hidden={row.intake_title==="삭제된 진료지" || row.user_name === "Unknown User"}>
                    <DetailViewer value={value} row={row}></DetailViewer>
                </div>
            ),
            align: "center"
        },
        {
            field: "purchase_detail",
            label: "구매정보",
            visible: true,
            render: (value, field, row) => (
                <div hidden={![CustomerState.DELIVERED, CustomerState.PAYMENT_COMPLETED, CustomerState.SHIPPING].includes(CustomerState[row.state])}>
                    <CalculatorIcon className="size-4 cursor-pointer" onClick={() => { openPurchaseViewerModal(row) }}></CalculatorIcon>
                </div>
            ),
            align: "center"
        },
        ...columnInfos?.map((info) => {
            if (["user_name", "clinic_user_id"].includes(info.field)) {
                return {
                    ...info,
                    visible: !isStickyFirstCol
                }
            }
            return info;
        })
    ]

    const columnInfosWithClinic = convertClinicIdColumnSelectable(columnInfosWithTasks, clinicInfos);

    if (!isReady) {
        return <SpinnerMedium />
    }

    return (
        <>
            <Card>
                <div className="p-3">
                    <PageTitle>진료상황 관리</PageTitle>
                    <div className="px-2">
                        <div className="flex pb-2 space-x-2 justify-between overflow-auto items-end">
                            <div className="flex space-x-2">
                                <div>
                                    <Select
                                        upperLabel="진료지"
                                        items={intakeItems}
                                        selectedItem={selectedIntakeItem}
                                        onChange={onIntakeFilterChanged}
                                        saveSelectedItems={true}
                                    />
                                </div>
                                <div>
                                    <MultiSelect
                                        upperLabel="상태"
                                        label={STATUS_FILTER.label}
                                        items={STATUS_FILTER.options}
                                        onChange={onStatusFilterChanged}
                                        enableSearch={false}
                                        saveSelectedItems={true}
                                    />
                                </div>
                                <div className="z-50">
                                    <RangeDatePicker
                                        upperLabel="응답지 제출일"
                                        onRangeChanged={onSubmissionDateRangeChanged}
                                        initialRange={[moment().subtract(1, "month").toDate(), moment().add(1, "day").toDate()]}
                                        saveSelectedDts={true}
                                    />
                                </div>
                                <div className="min-w-36">
                                    <ToggleSearchInput
                                        upperLabel="고객검색"
                                        upButtonLabel="적용"
                                        downButtonLabel="해제"
                                        onToggle={(isUp, value) => { setSearchText(!isUp ? value : null) }}
                                        placeholder="고객명, 고객번호, 전화번호 검색"
                                    />
                                </div>
                            </div>
                            <div className="flex space-x-2 items-end">
                                <div>
                                    <MultiSelect
                                        upperLabel="테이블 옵션"
                                        label={TABLE_OPTION_FILTER.label}
                                        items={TABLE_OPTION_FILTER.options}
                                        onChange={onTableOptionFilterChanged}
                                        enableSelectAll={false}
                                        enableSearch={false}
                                        saveSelectedItems={true}
                                    />
                                </div>
                                <div className="">
                                    <IconButton
                                        onButtonClicked={async () => {
                                            console.log("[download excel]", filterParams)
                                            AdminClient.downloadXlsx("/trackings/download/xlsx", filterParams, "downloaded_status.xlsx");
                                        }}
                                    >
                                        <div className="size-5">
                                            <img src={excelIcon} alt="excel" />
                                        </div>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <PagingTable
                            refreshKey={refreshKey}
                            fetchData={fetchData}
                            columns={columnInfosWithClinic}
                            rowInfo={rowInfos}
                            disableSort={true}
                            windowSize={20}
                            isStickyFirstCol={isStickyFirstCol}
                        />
                    </div>
                </div>
            </Card>
            <PurchaseViewerModal>
                <PurchaseViewer />
            </PurchaseViewerModal>
        </>
    )
}

export default AdminCustomerStatusPage;

