export const SYSTEM_TRS = [
    {
        origin: "회원가입 및 제출",
        translated: "会員登録および提出",
        translated_lang: "jp"
    },
    {
        origin: "라인 아이디로 제출",
        translated: "LINE IDで提出",
        translated_lang: "jp"
    },
    {
        origin: "회원가입 및 제출",
        translated: "会員登録および提出",
        translated_lang: "jp"
    },
    {
        origin: "라인 로그인이 완료되었습니다.",
        translated: "LINEログインが完了しました。",
        translated_lang: "jp"
    },
    {
        origin: "라인 로그인에 실패했습니다",
        translated: "LINEログインに失敗しました",
        translated_lang: "jp"
    },
    {
        origin: "상담 시트를 기반으로 처방하기 때문에 확실히 정확하고 정직하게 답변해 주세요.",
        translated: "※こちらのご回答内容により、お薬の強度や種類等が決まります為、誤りがないよう正確にお答えください。",
        translated_lang: "jp"
    },
    {
        origin: "시작하기",
        translated: "はじめる",
        translated_lang: "jp"
    },
    {
        origin: "아이디는 3자 이상이어야 합니다.",
        translated: "IDは3文字以上である必要があります。",
        translated_lang: "jp"
    },
    {
        origin: "이미 존재하는 아이디입니다.",
        translated: "すでに存在するIDです。",
        translated_lang: "jp"
    },
    {
        origin: "비밀번호는 4자 이상이어야 합니다.",
        translated: "パスワードは4文字以上である必要があります。",
        translated_lang: "jp"
    },
    {
        origin: "비밀번호가 일치하지 않습니다.",
        translated: "パスワードが一致しません。",
        translated_lang: "jp"
    },
    {
        origin: "아이디",
        translated: "ID",
        translated_lang: "jp"
    },
    {
        origin: "비밀번호",
        translated: "パスワード",
        translated_lang: "jp"
    },
    {
        origin: "비밀번호 확인",
        translated: "パスワード確認",
        translated_lang: "jp"
    },
    {
        origin: "수정",
        translated: "修正",
        translated_lang: "jp"
    },
    {
        origin: "입력",
        translated: "入力",
        translated_lang: "jp"
    },
    {
        origin: "제출",
        translated: "提出",
        translated_lang: "jp"
    },
    {
        origin: "진료지 제출이 완료되었습니다",
        translated_lang: "jp",
        translated: "診療所提出が完了しました"
    },
    {
        origin: "처음으로",
        translated: "最初に",
        translated_lang: "jp"
    },
    {
        origin: "회원가입 및 제출",
        translated: "Sign up and submit",
        translated_lang: "en"
    },
    {
        origin: "라인 아이디로 제출",
        translated: "Submit with LINE ID",
        translated_lang: "en"
    },
    {
        origin: "회원가입 및 제출",
        translated: "Sign up and submit",
        translated_lang: "en"
    },
    {
        origin: "라인 로그인이 완료되었습니다.",
        translated: "LINE login is complete.",
        translated_lang: "en"
    },
    {
        origin: "라인 로그인에 실패했습니다",
        translated: "LINE login failed.",
        translated_lang: "en"
    },
    {
        origin: "상담 시트를 기반으로 처방하기 때문에 확실히 정확하고 정직하게 답변해 주세요.",
        translated: "Since the prescription is based on the consultation sheet, please be sure to answer accurately and honestly.",
        translated_lang: "en"
    },
    {
        origin: "시작하기",
        translated: "Start",
        translated_lang: "en"
    },
    {
        origin: "아이디는 3자 이상이어야 합니다.",
        translated: "The ID must be at least 3 characters.",
        translated_lang: "en"
    },
    {
        origin: "이미 존재하는 아이디입니다.",
        translated: "The ID already exists.",
        translated_lang: "en"
    },
    {
        origin: "비밀번호는 4자 이상이어야 합니다.",
        translated: "The password must be at least 4 characters.",
        translated_lang: "en"
    },
    {
        origin: "비밀번호가 일치하지 않습니다.",
        translated: "Passwords do not match.",
        translated_lang: "en"
    },
    {
        origin: "아이디",
        translated: "ID",
        translated_lang: "en"
    },
    {
        origin: "비밀번호",
        translated: "Password",
        translated_lang: "en"
    },
    {
        origin: "비밀번호 확인",
        translated: "Confirm Password",
        translated_lang: "en"
    },
    {
        origin: "수정",
        translated: "Edit",
        translated_lang: "en"
    },
    {
        origin: "입력",
        translated: "Enter",
        translated_lang: "en"
    },
    {
        origin: "제출",
        translated: "Submit",
        translated_lang: "en"
    },
    {
        origin: "진료지 제출이 완료되었습니다",
        translated_lang: "en",
        translated: "Clinic submission is complete"
    },
    {
        origin: "처음으로",
        translated: "First",
        translated_lang: "en"
    },
    {
        origin: "확인",
        translated: "確認",
        translated_lang: "jp"
    },
    {
        origin: "확인",
        translated: "Confirm",
        translated_lang: "en"
    },
    // 사용자ID
    {
        origin: "사용자ID",
        translated: "User ID",
        translated_lang: "en"
    },
    {
        origin: "사용자ID",
        translated: "ユーザーID",
        translated_lang: "jp"
    },
    // 질문번호
    {
        origin: "질문번호",
        translated: "Question number",
        translated_lang: "en"
    },
    {
        origin: "질문번호",
        translated: "質問番号",
        translated_lang: "jp"
    },
    // 진료상황
    {
        origin: "진료상황",
        translated: "Medical situation",
        translated_lang: "en"
    },
    {
        origin: "진료상황",
        translated: "診療状況",
        translated_lang: "jp"
    },
    //제출목록
    {
        origin: "제출목록",
        translated: "Submission list",
        translated_lang: "en"
    },
    {
        origin: "제출목록",
        translated: "カウンセリングリスト",
        translated_lang: "jp"
    },
    // 계정
    {
        origin: "계정",
        translated: "Account",
        translated_lang: "en"
    },
    {
        origin: "계정",
        translated: "マイページ",
        translated_lang: "jp"
    },
    // 내 계정
    {
        origin: "내 계정",
        translated: "My account",
        translated_lang: "en"
    },
    {
        origin: "내 계정",
        translated: "基本情報",
        translated_lang: "jp"
    },
    // 내용확인
    {
        origin: "내용확인",
        translated: "Content confirmation",
        translated_lang: "en"
    },
    {
        origin: "내용확인",
        translated: "内容確認",
        translated_lang: "jp"
    },
    // 진료지제목
    {
        origin: "진료지제목",
        translated: "Medical title",
        translated_lang: "en"
    },
    {
        origin: "진료지제목",
        translated: "診療所タイトル",
        translated_lang: "jp"
    },
    // 상태
    {
        origin: "상태",
        translated: "Status",
        translated_lang: "en"
    },
    {
        origin: "상태",
        translated: "ステータス",
        translated_lang: "jp"
    },
    // 응답지 제출일
    {
        origin: "응답지 제출일",
        translated: "Response submission date",
        translated_lang: "en"
    },
    {
        origin: "응답지 제출일",
        translated: "提出日",
        translated_lang: "jp"
    },
    // 접수일
    {
        origin: "접수일",
        translated: "Date of receipt",
        translated_lang: "en"
    },
    {
        origin: "접수일",
        translated: "受付日",
        translated_lang: "jp"
    },
    // 질문
    {
        origin: "질문",
        translated: "Question",
        translated_lang: "en"
    },
    {
        origin: "질문",
        translated: "質問",
        translated_lang: "jp"
    },
    // 답변
    {
        origin: "답변",
        translated: "Answer",
        translated_lang: "en"
    },
    {
        origin: "답변",
        translated: "回答",
        translated_lang: "jp"
    },
    // 응답지
    {
        origin: "응답지",
        translated: "Response",
        translated_lang: "en"
    },
    {
        origin: "응답지",
        translated: "応答",
        translated_lang: "jp"
    },
    // 처방전
    {
        origin: "처방전",
        translated: "Prescription",
        translated_lang: "en"
    },
    {
        origin: "처방전",
        translated: "処方箋",
        translated_lang: "jp"
    },
    // 완료
    {
        origin: "완료",
        translated: "Complete",
        translated_lang: "en"
    },
    {
        origin: "완료",
        translated: "完了",
        translated_lang: "jp"
    },
    // 진료지 제출을 완료합니다.
    {
        origin: "진료지 제출을 완료합니다.",
        translated: "Complete the clinic submission.",
        translated_lang: "en"
    },
    {
        origin: "진료지 제출을 완료합니다.",
        translated: "診療所提出を完了します。",
        translated_lang: "jp"
    },
    // 회원가입
    {
        origin: "회원가입",
        translated: "Sign up",
        translated_lang: "en"
    },
    {
        origin: "회원가입",
        translated: "サインアップ",
        translated_lang: "jp"
    },
    // 라인 아이디 제출
    {
        origin: "라인 아이디 제출",
        translated: "Submit LINE ID",
        translated_lang: "en"
    },
    {
        origin: "라인 아이디 제출",
        translated: "LINEでカウンセリングを提出する",
        translated_lang: "jp"
    },

    // 로그인
    {
        origin: "로그인",
        translated: "Login",
        translated_lang: "en"
    },
    {
        origin: "로그인",
        translated: "ログイン",
        translated_lang: "jp"
    },
    // 라인으로 로그인
    {
        origin: "라인으로 로그인",
        translated: "Login with LINE",
        translated_lang: "en"
    },
    {
        origin: "라인으로 로그인",
        translated: "LINEでログイン",
        translated_lang: "jp"
    },
    // 계정으로 로그인
    {
        origin: "계정으로 로그인",
        translated: "Login with account",
        translated_lang: "en"
    },
    {
        origin: "계정으로 로그인",
        translated: "アカウントでログイン",
        translated_lang: "jp"
    },
    /*
        if (CustomerState[state] === CustomerState.AWAITING_SUBMISSION_TRANSLATION) return "처방전작성대기중";
    if (CustomerState[state] === CustomerState.AWAITING_PRESCRIPTION) return "처방전작성대기중";
    if (CustomerState[state] === CustomerState.AWAITING_PRESCRIPTION_TRANSLATION) return "처방전작성대기중";
    if (CustomerState[state] === CustomerState.AWAITING_CUSTOMER_NOTI) return "처방전작성대기중";
    if (CustomerState[state] === CustomerState.AWAITING_CUSTOMER_PAYMENT) return "구매대기중";
    if (CustomerState[state] === CustomerState.PAYMENT_COMPLETED) return "배송대기중";
    if (CustomerState[state] === CustomerState.SHIPPING) return "배송중";
    if (CustomerState[state] === CustomerState.DELIVERED) return "배송완료";
    if (CustomerState[state] === CustomerState.PURCHASE_FAILURE) return "구매진행중단";
    */
   // 처방전작성대기중
    {
         origin: "처방전작성대기중",
         translated: "Awaiting prescription writing",
         translated_lang: "en"
    },
    {
         origin: "처방전작성대기중",
         translated: "処方箋の作成を待っています",
         translated_lang: "jp"
    },
    // "배송대기중"
    {
        origin: "배송대기중",
        translated: "Waiting for delivery",
        translated_lang: "en"
    },
    {
        origin: "배송대기중",
        translated: "配送待ち",
        translated_lang: "jp"
    },
    // "배송중"
    {
        origin: "배송중",
        translated: "In delivery",
        translated_lang: "en"
    },
    {
        origin: "배송중",
        translated: "配送中",
        translated_lang: "jp"
    },
    // "배송완료"
    {
        origin: "배송완료",
        translated: "Delivery completed",
        translated_lang: "en"
    },
    {
        origin: "배송완료",
        translated: "配送完了",
        translated_lang: "jp"
    },
    // "구매진행중단"
    {
        origin: "구매진행중단",
        translated: "Purchase discontinued",
        translated_lang: "en"
    },
    {
        origin: "구매진행중단",
        translated: "購入中止",
        translated_lang: "jp"
    },
    // 사용자타입
    {
        origin: "사용자타입",
        translated: "User type",
        translated_lang: "en"
    },
    {
        origin: "사용자타입",
        translated: "ユーザータイプ",
        translated_lang: "jp"
    },
    // 이름
    {
        origin: "이름",
        translated: "Name",
        translated_lang: "en"
    },
    {
        origin: "이름",
        translated: "名前",
        translated_lang: "jp"
    },
    // 전화번호
    {
        origin: "전화번호",
        translated: "Phone number",
        translated_lang: "en"
    },
    {
        origin: "전화번호",
        translated: "電話番号",
        translated_lang: "jp"
    },
    // 생년월일
    {
        origin: "생년월일",
        translated: "Date of birth",
        translated_lang: "en"
    },
    {
        origin: "생년월일",
        translated: "生年月日",
        translated_lang: "jp"
    },
    // 성별
    {
        origin: "성별",
        translated: "性別",
        translated_lang: "jp"
    },
    {
        origin: "성별",
        translated: "Gender",
        translated_lang: "en"
    },
    // 국가
    {
        origin: "국가",
        translated: "Nationality",
        translated_lang: "en"
    },
    {
        origin: "국가",
        translated: "国籍",
        translated_lang: "jp"
    },
    // 포인트
    {
        origin: "포인트",
        translated: "Point",
        translated_lang: "en"
    },
    {
        origin: "포인트",
        translated: "ポイント",
        translated_lang: "jp"
    },
    // 가입일
    {
        origin: "가입일",
        translated: "Date of registration",
        translated_lang: "en"
    },
    {
        origin: "가입일",
        translated: "登録日",
        translated_lang: "jp"
    },
    // 항목
    {
        origin: "항목",
        translated: "Item",
        translated_lang: "en"
    },
    {
        origin: "항목",
        translated: "項目",
        translated_lang: "jp"
    },
    // 내용
    {
        origin: "내용",
        translated: "Content",
        translated_lang: "en"
    },
    {
        origin: "내용",
        translated: "内容",
        translated_lang: "jp"
    },
    // 로그아웃
    {
        origin: "로그아웃",
        translated: "Logout",
        translated_lang: "en"
    },
    {
        origin: "로그아웃",
        translated: "ログアウト",
        translated_lang: "jp"
    },
    // 아직 회원이 아니세요?
    {
        origin: "아직 회원이 아니세요?",
        translated: "Not a member yet?",
        translated_lang: "en"
    },
    {
        origin: "아직 회원이 아니세요?",
        translated: "まだ会員ではありませんか？",
        translated_lang: "jp"
    },
    // 뒤로가기
    {
        origin: "뒤로가기",
        translated: "Back",
        translated_lang: "en"
    },
    {
        origin: "뒤로가기",
        translated: "戻る",
        translated_lang: "jp"
    },
    //제출 목록 보기
    {
        origin: "제출 목록 보기",
        translated: "View submission list",
        translated_lang: "en"
    },
    {
        origin: "제출 목록 보기",
        translated: "カウンセリングリストを表示",
        translated_lang: "jp"
    },
    //라인 앱에서 로그인 되었습니다.
    {
        origin: "라인 앱에서 로그인 되었습니다.",
        translated: "Logged in from LINE app.",
        translated_lang: "en"
    },
    {
        origin: "라인 앱에서 로그인 되었습니다.",
        translated: "LINEアプリからログインしました。",
        translated_lang: "jp"
    },
    // 새로운 브라우저에서 로그인 되었습니다.
    {
        origin: "새로운 브라우저에서 로그인 되었습니다.",
        translated: "Logged in from a new browser.",
        translated_lang: "en"
    },
    {
        origin: "새로운 브라우저에서 로그인 되었습니다.",
        translated: "新しいブラウザからログインしました。",
        translated_lang: "jp"
    },
    // 라인 로그인이 완료되었습니다.
    {
        origin: "라인 로그인이 완료되었습니다.",
        translated: "LINE login is complete.",
        translated_lang: "en"
    },
    {
        origin: "라인 로그인이 완료되었습니다.",
        translated: "LINEログインが完了しました。",
        translated_lang: "jp"
    },
    // 로그아웃 되었습니다.
    {
        origin: "로그아웃 되었습니다.",
        translated: "Logged out.",
        translated_lang: "en"
    },
    {
        origin: "로그아웃 되었습니다.",
        translated: "ログアウトしました。",
        translated_lang: "jp"
    },
    // 포인트 내역
    {
        origin: "포인트 내역",
        translated: "Point history",
        translated_lang: "en"
    },
    {
        origin: "포인트 내역",
        translated: "ポイント履歴",
        translated_lang: "jp"
    },
    // 변동
    {
        origin: "변동",
        translated: "Change",
        translated_lang: "en"
    },
    {
        origin: "변동",
        translated: "ポイント",
        translated_lang: "jp"
    },
    // 변동이유
    {
        origin: "변동이유",
        translated: "Reason for change",
        translated_lang: "en"
    },
    {
        origin: "변동이유",
        translated: "ポイント内訳",
        translated_lang: "jp"
    },
    // 변동일
    {
        origin: "변동일",
        translated: "Change date",
        translated_lang: "en"
    },
    {
        origin: "변동일",
        translated: "日付",
        translated_lang: "jp"
    },
    //진료지 제출이 완료되었습니다
    {
        origin: "진료지 제출이 완료되었습니다",
        translated: "Clinic submission is complete",
        translated_lang: "en"
    },
    {
        origin: "진료지 제출이 완료되었습니다",
        translated: "診療所提出が完了しました",
        translated_lang: "jp"
    },
    //진료지 제출이 실패했습니다
    {
        origin: "진료지 제출이 실패했습니다",
        translated: "Clinic submission failed",
        translated_lang: "en"
    },
    {
        origin: "진료지 제출이 실패했습니다",
        translated: "診療所提出に失敗しました",
        translated_lang: "jp"
    },
    // 진료지가 이미 제출되었습니다.
    {
        origin: "진료지가 이미 제출되었습니다.",
        translated: "The clinic has already been submitted.",
        translated_lang: "en"
    },
    {
        origin: "진료지가 이미 제출되었습니다.",
        translated: "診療所はすでに提出されています。",
        translated_lang: "jp"
    },
    // 회원님 환영합니다. 진료지를 작성해주세요.
    {
        origin: "회원님 환영합니다. 진료지를 작성해주세요.",
        translated: "Welcome. Please fill out the clinic.",
        translated_lang: "en"
    },
    {
        origin: "회원님 환영합니다. 진료지를 작성해주세요.",
        translated: "ようこそ。診療所に記入してください。",
        translated_lang: "jp"
    },
    // 로그인이 필요한 진료지입니다.
    {
        origin: "로그인이 필요한 진료지입니다.",
        translated: "This is a clinic that requires login.",
        translated_lang: "en"
    },
    {
        origin: "로그인이 필요한 진료지입니다.",
        translated: "ログインが必要な診療所です。",
        translated_lang: "jp"
    },
    // "번역 데이터를 불러오는데 실패했습니다"
    {
        origin: "번역 데이터를 불러오는데 실패했습니다",
        translated: "Failed to load translation data",
        translated_lang: "en"
    },
    {
        origin: "번역 데이터를 불러오는데 실패했습니다",
        translated: "翻訳データの読み込みに失敗しました",
        translated_lang: "jp"
    },
    // "인적사항 진료지를 불러오는데 실패했습니다"
    {
        origin: "인적사항 진료지를 불러오는데 실패했습니다",
        translated: "Failed to load personal information clinic",
        translated_lang: "en"
    },
    {
        origin: "인적사항 진료지를 불러오는데 실패했습니다",
        translated: "個人情報診療所の読み込みに失敗しました",
        translated_lang: "jp"
    },
    // 진료지를 불러오는데 실패했습니다.
    {
        origin: "진료지를 불러오는데 실패했습니다.",
        translated: "Failed to load clinic.",
        translated_lang: "en"
    },
    {
        origin: "진료지를 불러오는데 실패했습니다.",
        translated: "診療所の読み込みに失敗しました。",
        translated_lang: "jp"
    },
    // 임시 제출에 실패했습니다.
    {
        origin: "임시 제출에 실패했습니다.",
        translated: "Temporary submission failed.",
        translated_lang: "en"
    },
    {
        origin: "임시 제출에 실패했습니다.",
        translated: "一時提出に失敗しました。",
        translated_lang: "jp"
    },
    // 진료지 제출이 완료되었습니다.
    {
        origin: "진료지 제출이 완료되었습니다.",
        translated: "Clinic submission is complete.",
        translated_lang: "en"
    },
    {
        origin: "진료지 제출이 완료되었습니다.",
        translated: "診療所提出が完了しました。",
        translated_lang: "jp"
    },
    // 진료지 제출이 실패했습니다.
    {
        origin: "진료지 제출이 실패했습니다.",
        translated: "Clinic submission failed.",
        translated_lang: "en"
    },
    {
        origin: "진료지 제출이 실패했습니다.",
        translated: "診療所提出に失敗しました。",
        translated_lang: "jp"
    },
    // 회원 정보를 찾을 수 없습니다.
    {
        origin: "회원 정보를 찾을 수 없습니다.",
        translated: "Member information not found.",
        translated_lang: "en"
    },
    {
        origin: "회원 정보를 찾을 수 없습니다.",
        translated: "会員情報が見つかりません。",
        translated_lang: "jp"
    },

    // LINE으로 로그인해 주시는 것으로 제출을 완료하겠습니다.
    {
        origin: "LINE으로 로그인해 주시는 것으로 제출을 완료하겠습니다.",
        translated: "Submission of consultation will be completed by logging in to LINE.",
        translated_lang: "en"
    },
    {
        origin: "LINE으로 로그인해 주시는 것으로 제출을 완료하겠습니다.",
        translated: "LINEでログインしていただくことで提出が完了します。",
        translated_lang: "jp"
    },

    // 다음
    {
        origin: "다음",
        translated: "Next",
        translated_lang: "en"
    },
    {
        origin: "다음",
        translated: "つぎへ",
        translated_lang: "jp"
    },
    // 이전
    {
        origin: "이전",
        translated: "Previous",
        translated_lang: "en"
    },
    {
        origin: "이전",
        translated: "まえへ",
        translated_lang: "jp"
    },
    // 제출
    {
        origin: "제출",
        translated: "Submit",
        translated_lang: "en"
    },
    {
        origin: "제출",
        translated: "ていしゅつ",
        translated_lang: "jp"
    },
    // 날짜를 입력해 주세요
    {
        origin: "날짜를 입력해 주세요",
        translated: "Please enter the date",
        translated_lang: "en"
    },
    {
        origin: "날짜를 입력해 주세요",
        translated: "日付を入力してください",
        translated_lang: "jp"
    },
    // 시간을 입력해 주세요
    {
        origin: "시간을 입력해 주세요",
        translated: "Please enter the time",
        translated_lang: "en"
    },
    {
        origin: "시간을 입력해 주세요",
        translated: "時間を入力してください",
        translated_lang: "jp"
    },
    // 처방전이 아직 작성되지 않았습니다.
    {
        origin: "처방전이 아직 작성되지 않았습니다.",
        translated: "The prescription has not yet been written.",
        translated_lang: "en"
    },
    {
        origin: "처방전이 아직 작성되지 않았습니다.",
        translated: "処方箋はまだ書かれていません。",
        translated_lang: "jp"
    },
    // 라인 사용자명
    {
        origin: "라인 사용자명",
        translated: "LINE username",
        translated_lang: "en"
    },
    {
        origin: "라인 사용자명",
        translated: "LINEユーザー名",
        translated_lang: "jp"
    },
    // 제출 목록
    {
        origin: "제출 목록",
        translated: "Submission list",
        translated_lang: "en"
    },
    {
        origin: "제출 목록",
        translated: "提出リスト",
        translated_lang: "jp"
    },
    // 구매대기중
    {
        origin: "구매대기중",
        translated: "Waiting for purchase",
        translated_lang: "en"
    },
    {
        origin: "구매대기중",
        translated: "購入待ち",
        translated_lang: "jp"
    },
    // 구매내역
    {
        origin: "구매내역",
        translated: "Purchase history",
        translated_lang: "en"
    },
    {
        origin: "구매내역",
        translated: "購入履歴",
        translated_lang: "jp"
    },
    // 구매일
    {
        origin: "구매일",
        translated: "Purchase date",
        translated_lang: "en"
    },
    {
        origin: "구매일",
        translated: "購入日",
        translated_lang: "jp"
    },
    // 통화
    {
        origin: "통화",
        translated: "Currency",
        translated_lang: "en"
    },
    {
        origin: "통화",
        translated: "通貨",
        translated_lang: "jp"
    },
    // 주 상품 구매가격
    {
        origin: "주 상품 구매가격",
        translated: "Main product purchase price",
        translated_lang: "en"
    },
    {
        origin: "주 상품 구매가격",
        translated: "メイン商品購入価格",
        translated_lang: "jp"
    },
    // 보조 상품 구매가격
    {
        origin: "보조 상품 구매가격",
        translated: "Sub-product purchase price",
        translated_lang: "en"
    },
    {
        origin: "보조 상품 구매가격",
        translated: "サブ商品購入価格",
        translated_lang: "jp"
    },
    // 구매제품
    {
        origin: "구매제품",
        translated: "Purchased product",
        translated_lang: "en"
    },
    {
        origin: "구매제품",
        translated: "購入商品",
        translated_lang: "jp"
    },
    //배송지
    {
        origin: "배송지",
        translated: "Shipping address",
        translated_lang: "en"
    },
    {
        origin: "배송지",
        translated: "配送先",
        translated_lang: "jp"
    },

    // 배송접수일
    {
        origin: "배송접수일",
        translated: "Delivery receipt date",
        translated_lang: "en"
    },
    {
        origin: "배송접수일",
        translated: "配送受付日",
        translated_lang: "jp"
    },
    // 배송사
    {
        origin: "배송사",
        translated: "Delivery company",
        translated_lang: "en"
    },
    {
        origin: "배송사",
        translated: "配送会社",
        translated_lang: "jp"
    },
    // 송장번호
    {
        origin: "송장번호",
        translated: "Waybill number",
        translated_lang: "en"
    },
    {
        origin: "송장번호",
        translated: "伝票番号",
        translated_lang: "jp"
    },
    // 배송조회
    {
        origin: "배송조회",
        translated: "Delivery inquiry",
        translated_lang: "en"
    },
    {
        origin: "배송조회",
        translated: "配送照会",
        translated_lang: "jp"
    },
    // 배송일
    {
        origin: "배송일",
        translated: "Delivery date",
        translated_lang: "en"
    },
    {
        origin: "배송일",
        translated: "配送日",
        translated_lang: "jp"
    },
    // 배송완료일
    {
        origin: "배송완료일",
        translated: "Delivery completion date",
        translated_lang: "en"
    },
    {
        origin: "배송완료일",
        translated: "配送完了日",
        translated_lang: "jp"
    },
    //MIVIEW 공식 
    {
        origin: "MIVIEW 공식 ",
        translated: "MIVIEW official",
        translated_lang: "en"
    },
    {
        origin: "MIVIEW 공식 ",
        translated: "MIVIEW公式",
        translated_lang: "jp"
    },
    //LINE 친구추가로 첫회 500포인트(500엔 상당)
    {
        origin: "LINE 친구추가로 첫회 500포인트(500엔 상당)",
        translated: "500 points (worth 500 yen) for the first time by adding LINE friends",
        translated_lang: "en"
    },
    {
        origin: "LINE 친구추가로 첫회 500포인트(500엔 상당)",
        translated: "LINEの友達追加で初回500ポイント(500円相当)",
        translated_lang: "jp"
    },
    //선물중
    {
        origin: " 선물중",
        translated: "Gift in progress",
        translated_lang: "en"
    },
    {
        origin: " 선물중",
        translated: "プレゼント中",
        translated_lang: "jp"
    },
    //당일 이용 가능한 포인트를 이번 기회에 GET하세요!
    {
        origin: "당일 이용 가능한 포인트를 이번 기회에 GET하세요!",
        translated: "Get the points available on the day this time!",
        translated_lang: "en"
    },
    {
        origin: "당일 이용 가능한 포인트를 이번 기회에 GET하세요!",
        translated: "即日利用可能なポイントをこの機会にGETしよう！",
        translated_lang: "jp"
    },
    //모든 질문에 답변해주세요
    {
        origin: "모든 질문에 답변해주세요",
        translated: "Please answer all questions",
        translated_lang: "en"
    },
    {
        origin: "모든 질문에 답변해주세요",
        translated: "すべての質問に回答してください",
        translated_lang: "jp"
    },
    // 닫기
    {
        origin: "닫기",
        translated: "Close",
        translated_lang: "en"
    },
    {
        origin: "닫기",
        translated: "閉じる",
        translated_lang: "jp"
    },
    // 친구추가
    {
        origin: "친구추가",
        translated: "Add friend",
        translated_lang: "en"
    },
    {
        origin: "친구추가",
        translated: "友だち追加",
        translated_lang: "jp"
    },
]