import React, { useEffect, useState } from "react";
import Dropdown from "components/Form/Dropdown";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Column } from "components/Table/Simple";
import { formatDate, formatDateTime, truncate } from "components/Table/Simple/presets";
import _ from 'lodash';
import { formatStatus, renderStatusNumber } from "pages/Admin/Customer/Status/config";
import DetailViewer from "pages/Admin/Customer/Status/DetailViewer";
import { formatShippingCompanyName, formatShippingLink } from "pages/Admin/Task/InputDeliveryInfo/helper";
import { SHIPPING_COMPANY_ITEMS } from "pages/Admin/Customer/Status/TaskManager";
import Checkbox from "components/Form/Checkbox";
import { adminClient } from "apis/admin";
import { toast } from "react-toastify";

export const columnInfos: Column[] = [
    {
        field: "_id",
        label: "id",
        visible: false
    },
    {
        field: "clinic_detail",
        label: "진료내용",
        visible: true,
        render: (value, field, row) => <DetailViewer value={value} row={row}></DetailViewer>,
        align: "center"
    },
    {
        field: "purchase_detail",
        label: "구매정보",
        visible: false
    },
    {
        field: "clinic_user_id",
        label: "고객번호",
        visible: true,
        render: (value, field, row) => {
            return value ?? ""
        }
    },
    {
        field: "nudge",
        label: "확인중",
        visible: true,
        render: (value, field, row) => {
            return <Checkbox initialChecked={value} onChange={async () => {
                try {
                    await adminClient.patch(`/trackings/${row.submission_id}/field`, {
                        nudge: !value
                    });
                    toast.success("확인중 상태가 변경되었습니다.");
                } catch (e) {
                    console.error(e);
                    toast.error("확인중 상태 변경에 실패했습니다.");
                }
            }} />
        }
    },
    {
        field: "nudge_memo",
        label: "확인중 메모",
        visible: false,
    },
    {
        field: "user_name",
        label: "고객명",
        visible: true,
        // multiselect: true
    },
    {
        field: "phone_number",
        label: "전화번호",
        visible: true,
    },
    {
        field: "user_id",
        label: "사용자ID",
        visible: true,
        render: truncate,
        // multiselect: true
    },

    {
        field: "intake_id",
        label: "진료지ID",
        visible: false,
    },
    {
        field: "intake_version",
        label: "진료지버전",
        visible: false,
    },
    {
        field: "clinic_id",
        label: "의원ID",
        visible: false,
    },
    {
        field: "intake_title",
        label: "진료지제목",
        visible: true,
        // multiselect: true
    },
    {
        field: "submission_id",
        label: "응답지번호",
        align: "center",
        visible: true,
    },
    {
        field: "state_number",
        label: "상태번호",
        visible: true,
        align: "center",
        mutate: renderStatusNumber
    },
    {
        field: "state",
        label: "상태",
        visible: true,
        render: formatStatus,
        // multiselect: true,
    },
    {
        field: "purchase_failure_reason",
        label: "구매실패사유",
        visible: false,
    },
    {
        field: "created_at",
        label: "응답지 제출일",
        visible: true,
        render: formatDate
    },
    {
        field: "submission_translated_at",
        label: "응답지 번역일",
        visible: true,
        render: formatDate
    },
    {
        field: "awaiting_at",
        label: "구매대기시작일",
        visible: true,
        render: formatDate
    },
    {
        field: "purchase_at",
        label: "구매일",
        visible: true,
        render: formatDate
    },
    {
        field: "address",
        label: "회원주소",
        visible: false,
    },
    {
        field: "shipping_address",
        label: "배송지",
        visible: true,
    },
    {
        field: "deliver_request_at",
        label: "배송접수일",
        visible: true,
        render: formatDate
    },
    {
        field: "shipping_company",
        label: "배송사",
        visible: true,
        render: formatShippingCompanyName
    },
    {
        field: "tracking_number",
        label: "송장번호",
        visible: true,
    },
    {
        field: "tracking_shipping_link",
        label: "배송조회",
        render: formatShippingLink,
        align: "center",
    },
    {
        field: "deliver_at",
        label: "배송시작일",
        visible: true,
        render: formatDate
    },
    {
        field: "delivered_at",
        label: "배송완료일",
        visible: true,
        render: formatDate
    },
    {
        field: "updated_at",
        label: "마지막 수정시간",
        visible: true,
        render: formatDateTime
    },
    {
        field: "unpaid_noti",
        label: "미납알림",
        visible: false,
    },
];
