import React, { useRef, useState } from "react";
import GeneralTaskList, { RangePickerOption } from "containers/Task/GeneralTaskList";
import { columnInfos } from "containers/Task/GeneralTaskList/config";
import { CustomerState } from "pages/Admin/Customer/Status/config";
import Button from "components/Form/Button";
import { useModal } from "components/Modal/Simple";
import { TrackingNumberInputForm } from "pages/Admin/Customer/Status/TaskManager";
import { randomString } from "utils/math";
import { SimpleDatePicker } from "components/Form/Datepicker";
import { adminClient } from "apis/admin";
import moment from "moment-timezone";
import _ from "lodash";
import { toast } from "react-toastify";
import Card from "components/Card";
import PageTitle from "components/Title/Page";
import { CalculatorIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { formatDate } from "components/Table/Simple/presets";
import { DateEditForm } from "pages/Admin/Customer/Unpaid";
import Checkbox from "components/Form/Checkbox";
import SimpleTable from "components/Table/Simple";
import Dropdown from "components/Form/Dropdown";
import PurchaseViewer from "pages/Admin/Customer/Status/PurchaseViewer";
import { useDateDialog } from "components/Dialog/Date";
import { useInputDialog } from "components/Dialog/Input";
// import { useTextareaDialog } from "components/Dialog/Textarea";
import { formatShippingCompanyName } from "./helper";
import BasicArticle from "components/Article/Basic";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from 'react-tooltip'

const RANGE_PICKER_OPTIONS: RangePickerOption[] = [
    {
        label: "모든 날짜",
        field: null
    },
    {
        label: "구매일",
        field: "purchase_at"
    },
    {
        label: "배송접수일",
        field: "deliver_request_at"
    }
]

const ExcelGuide = () => {
    return (
        <div className="mt-1">
            <BasicArticle>
                <div>
                    ※ 엑셀 송장번호 입력 가이드
                </div>
            </BasicArticle>
            <BasicArticle>
                <div>
                    - 폼에서 선택된 배송접수일 기준
                </div>
            </BasicArticle>
            <BasicArticle>
                <div>
                    - 엑셀의 배송시작일
                </div>
            </BasicArticle>
            <BasicArticle>
                <div>
                    위 세가지가 모두 동일해야 송장번호가 입력됩니다.
                </div>
            </BasicArticle>
        </div>
    )
}

const TrackingNumberMultiInputForm = ({ onSubmit = (successUsers) => { } }) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [file, setFile] = useState(null);

    const handleSubmit = async () => {
        console.log("[handleSubmit]", startDate, endDate, file);
        const formData = new FormData();
        formData.append("file", file);
        const nextDate = new Date(startDate);
        nextDate.setDate(nextDate.getDate() + 1);

        formData.append("startDate", moment(startDate).format("YYYY-MM-DD"));
        formData.append("endDate", moment(nextDate).format("YYYY-MM-DD"));
        try {
            const ret = await adminClient.post(`/trackings/upload/tracking_number`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            console.log("[handleSubmit] ret", ret);
            const { successUsers } = ret.data;
            if (_.isEmpty(successUsers)) {
                toast.warn("송장번호가 매칭된 항목이 없습니다.");
                return;
            }
            toast.success("송장번호 일괄 입력에 성공했습니다.");
            onSubmit(successUsers);
        } catch (e) {
            toast.error("송장번호 일괄 입력에 실패했습니다.");
            console.error(e);
        }
    }

    return (
        <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-[1px]">
                <h1 className="text-lg font-bold">
                    송장번호 일괄 입력
                </h1>
                <div className="">
                    <InformationCircleIcon className="size-[17px] text-gray-600 excel-tooltip"></InformationCircleIcon>
                </div>
            </div>
            <div>
                <h1 className="text-sm font-bold">배송접수일 기준</h1>
                <SimpleDatePicker
                    selectedDt={startDate}
                    onDtChanged={setStartDate}
                />
            </div>
            {/* <div>
                <h1 className="text-sm font-bold">종료일</h1>
                <SimpleDatePicker
                    selectedDt={endDate}
                    onDtChanged={setEndDate}
                />
            </div> */}
            <div>
                <h1 className="text-sm font-bold">파일 업로드</h1>
                <div>
                    <input className="block w-full rounded-md border-0 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 min-w-fit file:cursor-pointer cursor-pointer file:border-0 file:px-4 file:mr-4 file:bg-gray-150 file:hover:bg-gray-200 file:text-gray-700 file:rounded-md" id="file_input" type="file"
                        onChange={(e) => {
                            setFile(e.target.files[0]);
                        }}
                    ></input>
                </div>
            </div>
            <div>
                <div className="mt-1 mb-2">
                    <Button
                        onButtonClicked={handleSubmit}
                        disabled={_.isNil(file)}
                    >
                        입력
                    </Button>
                </div>
            </div>
            <Tooltip anchorSelect=".excel-tooltip" place="right" variant="light" border="1px solid #cccccc"  >
                <ExcelGuide></ExcelGuide>
            </Tooltip>
        </div>
    )
}

const InputDeliveryInfo = () => {
    const states: (keyof typeof CustomerState)[] = ["PAYMENT_COMPLETED"]
    const [submissionId, setSubmissionId] = useState(null);
    const [randomKey, setRandomKey] = useState(randomString());
    const { Modal: TrackingNumberInputModal, openModal: openTrackingNumberInputModal, closeModal: closeTrackingNumberInputModal } = useModal();
    const { Modal: TranckingNumberMultiInputModal, openModal: openTrackingNumberMultiInputModal, closeModal: closeTrackingNumberMultiInputModal } = useModal();
    const { Modal: DateEditModal, openModal: openDateEditModal, closeModal: closeDateEditModal } = useModal();
    const { Modal: MultiInputResultModal, openModal: openMultiInputResultModal, closeModal: closeMultiInputResultModal } = useModal();
    const { Modal: PurchaseViewerModal, openModal: openPurchaseViewerModal, closeModal: closePurchaseViewerModal } = useModal();
    const { DialogProvider: DateDialogProvider, openDialog: openDateDialog } = useDateDialog();
    const { DialogProvider: InputDialogProvider, openDialog: openInputDialog } = useInputDialog();
    // const { DialogProvider: TextareaDialogProvider, openDialog: openTextareaDialog } = useTextareaDialog();
    const [multiInputReslt, setMultiInputResult] = useState(null);
    const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);
    const [selectedField, setSelectedField] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const checkedIdsRef = useRef([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);

    const columnInfosWithTask = [
        // {
        //     field: "task",
        //     label: "작업",
        //     visible: true,
        //     render: (value, field, row) => (
        //         <div className="flex space-x-2">
        //             <Button
        //                 onButtonClicked={() => { onEditFormButtonClicked(row, "deliver_request_at") }}
        //             >
        //                 <div className="text-xs">
        //                     배송접수일 입력
        //                 </div>
        //             </Button>
        //             <Button
        //                 onButtonClicked={() => { onInputButtonClicked(row.submission_id) }}
        //             >
        //                 <div className="text-xs">
        //                     송장번호 입력
        //                 </div>
        //             </Button>
        //         </div>
        //     ),
        //     align: "center"
        // },
        {
            field: "customer_uid",
            label: "고객명",
            visible: true,
            render: (value, field, row) => {
                return (
                    <div className="flex space-x-1.5">
                        <Checkbox initialChecked={isAllSelected} data={row.submission_id} onChange={onCheckboxesChanged} onInitialized={onCheckboxesChanged} />
                        <div className="">{row.user_name}</div>
                    </div>)
            }
        },
        ...columnInfos?.map((info) => {
            if (["user_name"].includes(info.field)) {
                return {
                    ...info,
                    visible: false
                }
            }
            if (info.field === "purchase_detail") {
                return {
                    ...info,
                    render: (value, field, row) => <CalculatorIcon className="size-4 cursor-pointer" onClick={() => { openPurchaseViewerModal(row) }}></CalculatorIcon>,
                    visible: true,
                    align: "center"
                }
            }
            if ([
                "deliver_at",
                "delivered_at",
                "nudge"
            ].includes(info.field)) {
                return {
                    ...info,
                    visible: false
                }
            }
            return info;
        })
    ]

    const onTrackingNumberSubmit = () => {
        closeTrackingNumberInputModal();
        setRandomKey(randomString());
    }

    const onTrackingNumberMultiInputSubmit = async (successUsers) => {
        closeTrackingNumberMultiInputModal();
        setMultiInputResult(successUsers);
        openMultiInputResultModal();
        setRandomKey(randomString());
    }

    const onInputButtonClicked = (submissionId) => {
        setSubmissionId(submissionId);
        openTrackingNumberInputModal();
    }

    const onCheckboxesChanged = (checked, submissionId) => {
        console.log("[onCheckboxesChanged] checked", checked, "submissionId", submissionId)
        const newCheckedDatas = _.uniq([...checkedIdsRef.current]);
        if (checked) {
            newCheckedDatas.push(submissionId);
        } else {
            _.remove(newCheckedDatas, (elm) => elm === submissionId);
        }
        const uniqueCheckedDatas = _.uniq(newCheckedDatas);
        console.log("[onCheckboxesChanged] newCheckedDatas", uniqueCheckedDatas);
        checkedIdsRef.current = uniqueCheckedDatas;
        setSelectedIds(uniqueCheckedDatas);
    }

    const onDateEditFormButtonClicked = (row, field) => {
        console.log("[onDateEditFormButtonClicked]", row, field);
        openDateEditModal();
        setSelectedField(field);
        if (row === "MULTIPLE") {
            setSelectedSubmissionId("MULTIPLE");
            setSelectedData(null);
            return;
        }
        setSelectedSubmissionId(row.submission_id);
        setSelectedData(row[field]);
    }

    const onEditFormSubmit = async (id, field, newData) => {
        console.log("[onEditFormSubmit]", id, field, newData);
        const ok = window.confirm("수정하시겠습니까?");
        if (!ok) {
            return;
        }
        if (id === "MULTIPLE") {
            onMultiDeliverRequestAtSubmitted(newData);
            return;
        }
        const body = {
            [`${field}`]: newData
        }
        try {
            const ret = await adminClient.patch(`/trackings/${id}/field`, body);
            console.log("[onEditFormSubmit] ret", ret);
            toast.success("수정되었습니다.");
            setRandomKey(randomString());
            closeDateEditModal();
        } catch (e) {
            toast.error("수정에 실패했습니다.");
        }
    }

    const onMultiDeliverRequestAtSubmitted = async (newData) => {
        console.log("[onMultiDeliverRequestAtSubmitted] checked newData", checkedIdsRef.current, newData);
        const checkedIds = checkedIdsRef.current;
        try {
            const body = {
                submission_ids: checkedIds,
                deliverRequestAt: newData
            }
            const ret = await adminClient.patch(`/trackings/deliver_request/multiple`, body);
            console.log("[onMultiDeliverRequestAtSubmitted] ret", ret);
            toast.success("수정되었습니다.");
            setRandomKey(randomString());
            closeDateEditModal();
        } catch (e) {
            toast.error("수정에 실패했습니다.");
        }
    }

    const onMultipleCompleteButtonClicked = async () => {
        const checkedIds = checkedIdsRef.current;
        console.log("[onMultipleCompleteButtonClicked] checkedIds", checkedIds);
        if (_.isEmpty(checkedIds)) {
            toast.warn("선택된 항목이 없습니다.");
            return;
        }
        const deliverDate = await openDateDialog("배송시작일", new Date());
        if (_.isNil(deliverDate)) {
            return;
        }
        const nItems = checkedIds?.length;
        const date = moment(deliverDate).format("YYYY-MM-DD");
        const ok = window.confirm(`총 ${nItems}건을 배송중 상태로 변경하시겠습니까? (배송시작일: ${date})`);
        if (!ok) {
            return;
        }
        try {
            // /trackings/shipping/multiple
            const body = {
                submission_ids: checkedIds,
                deliverDate
            }
            const ret = await adminClient.patch(`/trackings/shipping/multiple`, body);
            console.log("[onMultipleCompleteButtonClicked] ret", ret);
            toast.success(`총 ${nItems}건이 배송중 상태로 변경되었습니다.`);
            checkedIdsRef.current = [];
            setSelectedIds([]);
            setRandomKey(randomString());
        } catch (e) {
            toast.error("배송중 처리에 실패했습니다.");
        }
    }

    const onShippingAddressClicked = async (row) => {
        const { submission_id, shipping_address } = row;
        const address = await openInputDialog("배송지 주소", shipping_address, "w-[80vw] max-w-3xl");
        // const address = await openTextareaDialog("배송지 주소", shipping_address);
        if (_.isNil(address)) {
            return;
        }
        console.log("[onShippingAddressClicked] ret", address);
        const ok = window.confirm("수정하시겠습니까?");
        try {
            if (!ok) {
                return;
            }
            const body = {
                shipping_address: address
            }
            const ret = await adminClient.patch(`/trackings/${submission_id}/field`, body);
            console.log("[onShippingAddressClicked] ret", ret);
            toast.success("수정되었습니다.");
            setRandomKey(randomString());
        } catch (e) {
            toast.error("수정에 실패했습니다");
        }
    }

    const columnInfosEditForms = columnInfosWithTask.map((elm) => {
        if (["shipping_address"].includes(elm.field)) {
            return {
                ...elm,
                render: (value, field, row) => (
                    <div className="flex space-x-2 items-center cursor-pointer" onClick={() => { onShippingAddressClicked(row) }}>
                        <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                        <div>{value}</div>
                    </div>
                )
            }
        }
        if (["shipping_company"].includes(elm.field)) {
            return {
                ...elm,
                render: (value, field, row) => (
                    <div className="flex space-x-2 items-center cursor-pointer" onClick={() => { onInputButtonClicked(row.submission_id) }}>
                        <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                        <div>{formatShippingCompanyName(value, field, row)}</div>
                    </div>
                )
            }
        }
        if (["tracking_number"].includes(elm.field)) {
            return {
                ...elm,
                render: (value, field, row) => (
                    <div className="flex space-x-2 items-center cursor-pointer" onClick={() => { onInputButtonClicked(row.submission_id) }}>
                        <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                        <div>{value}</div>
                    </div>
                )
            }
        }
        if (["deliver_request_at"].includes(elm.field)) {
            return {
                ...elm,
                render: (value, field, row) => (
                    <div className="flex space-x-2 items-center cursor-pointer" onClick={() => { onDateEditFormButtonClicked(row, field) }}>
                        <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                        <div>{formatDate(value)}</div>
                    </div>
                )
            }
        }
        return elm;
    })

    const tasks = [
        {
            name: "송장번호 엑셀 입력",
            action: () => { openTrackingNumberMultiInputModal(); }
        },
        {
            name: "배송접수일 입력",
            action: () => { onDateEditFormButtonClicked("MULTIPLE", "deliver_request_at"); },
            disabled: _.isEmpty(checkedIdsRef.current)
        },
        null,
        {
            name: "배송중 처리",
            action: () => { onMultipleCompleteButtonClicked() },
            disabled: _.isEmpty(checkedIdsRef.current)
        }
    ]

    return (
        <>
            <Card>
                <div className="p-3">
                    <PageTitle>배송정보 입력</PageTitle>
                    <div className="mb-2">
                        <GeneralTaskList
                            states={states}
                            columnInfos={columnInfosEditForms}
                            rangePickerOptions={RANGE_PICKER_OPTIONS}
                            enableIntakeFilter={false}
                            enableClinicFilter={true}
                            enableShippingCompanyFilter={true}
                            randomKeyExternal={randomKey}
                        >
                            <div className="flex justify-end space-x-2">
                                <div>
                                    <Button
                                        onButtonClicked={() => { setIsAllSelected(!isAllSelected); setRandomKey(randomString()) }}
                                    >
                                        {isAllSelected ? "전체선택 해제" : "전체선택"}
                                    </Button>
                                </div>
                                <div>
                                    <Dropdown
                                        CustomMenuButton={() => {
                                            return (
                                                <Button>
                                                    일괄 작업
                                                </Button>
                                            )
                                        }}
                                        items={tasks}
                                    />
                                </div>
                            </div>
                        </GeneralTaskList>
                    </div>
                </div>
            </Card>
            <TrackingNumberInputModal>
                <TrackingNumberInputForm submissionId={submissionId} onSubmit={onTrackingNumberSubmit} />
            </TrackingNumberInputModal>
            <TranckingNumberMultiInputModal>
                <TrackingNumberMultiInputForm onSubmit={onTrackingNumberMultiInputSubmit} />
            </TranckingNumberMultiInputModal>
            <MultiInputResultModal>
                <div>
                    <PageTitle>송장번호 입력 결과</PageTitle>
                    <div className="flex flex-col mt-3">
                        <SimpleTable data={multiInputReslt} columns={[
                            {
                                field: "user_name",
                                label: "고객명"
                            },
                            {
                                field: "tracking_number",
                                label: "송장번호",
                            }
                        ]}></SimpleTable>
                        <div className="flex justify-end">
                            <div>
                                <Button onButtonClicked={closeMultiInputResultModal}>
                                    닫기
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </MultiInputResultModal>
            <DateEditModal>
                <DateEditForm id={selectedSubmissionId} field={selectedField} currentDt={selectedData} onSubmit={onEditFormSubmit} ></DateEditForm>
            </DateEditModal>
            <PurchaseViewerModal>
                <PurchaseViewer />
            </PurchaseViewerModal>
            <DateDialogProvider></DateDialogProvider>
            <InputDialogProvider></InputDialogProvider>
            {/* <TextareaDialogProvider></TextareaDialogProvider> */}
        </>
    )
}

export default InputDeliveryInfo;
