import React, { useEffect, useState } from 'react';
import Bullets from "components/Steps/Bullets";
import Progress from "components/Steps/Progress";
import QuestionContainer from "containers/Questions/Question";
import Button from "components/Form/Button";
import { Question, QuestionType, defaultValueOf } from "containers/Questions/Question/types";
import _, { isEmpty, set } from "lodash";
import { Answer } from 'pages/Intake/helper';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'utils/translate';
import { toast } from 'react-toastify';
import { scrollToElm } from 'components/uiUtils';

// short answar question
// const EXAMPLE_QUESTION = {
//     question_id: "1",
//     text: "What is your name?",
//     type: QuestionType["SHORT_ANSWER"],
// }

// long answar question
// const EXAMPLE_QUESTION = {
//     question_id: "1",
//     text: "Any other information you would like to share with us",
//     type: QuestionType["LONG_ANSWER"],
// }

// single choice question with options
// const EXAMPLE_QUESTION = {
//     question_id: "1",
//     text: "What is your Gender?",
//     type: QuestionType["SINGLE_CHOICE"],
//     options: ["Female", "Male"],
// }

// multiple choice question with options
const EXAMPLE_QUESTION: any = {
    question_id: "1",
    text: "What is your Gender?",
    type: QuestionType["MULTIPLE_CHOICE"],
    options: ["Female", "Male", "Other"],
}

// date question
// const EXAMPLE_QUESTION = {
//     question_id: "1",
//     text: "When were you born?",
//     type: QuestionType["DATE"],
// }

// questions is Question Array
export interface QuestionsProps {
    questions: Question[];
    sectionId?: string;
    onSubmit?: (answers: any) => Promise<boolean>;
}

// export const EXAMPLE_LOCAL_ANSWERS = "example_answers";
// export const EXAMPLE_LOCAL_PAGE_INDEX = "example_page_index";

const createEmptyAnswers = (questions: Question[]) => {
    const buildAnswers = (qs: Question[], acc = {}) => {
        qs.forEach((q) => {
            // acc[q.question_id] = defaultValueOf(q.type);
            acc[q.question_id] = null;
            if (q.conditional_questions) {
                buildAnswers(q.conditional_questions, acc);
            }
        });
        return acc;
    };

    return buildAnswers(questions);
};

type AnswerType = string | string[] | Date;
type AnswerMap = { [key: string]: AnswerType };

const checkRequiredAnswers = (rootQuestion: Question, answers: AnswerMap) => {
    const checkAnswers = (question: Question, answers: AnswerMap) => {
        if (_.isNil(question)) {
            return false;
        }
        const answer = answers[question.question_id];
        if (answer === null || answer === "" || (answer instanceof Array && isEmpty(answer))) {
            return false;
        }
        if (question.conditional_questions) {
            for (let i = 0; i < question.conditional_questions.length; i++) {
                const cq = question.conditional_questions[i];
                const conditionAnswer = question.options.find((option) => option.option_id === cq.condition)?.text; //
                if (answer === conditionAnswer || (answer instanceof Array && answer.includes(conditionAnswer))) {
                    const childAnswered = checkAnswers(cq, answers);
                    if (!childAnswered) {
                        return false;
                    }
                }
            }
        }

        return true;
    };

    return checkAnswers(rootQuestion, answers);
};
export default ({ questions, sectionId = "0", onSubmit = async (answers) => { return true } }: QuestionsProps) => {
    const localStorageAnswers = `${sectionId}_answers`;
    const localStoragePageIndex = `${sectionId}_page_index`;
    const localStorageMaxPageIndex = `${sectionId}_max_page_index`;

    const [isSpanMode, setIsSpanMode] = useState(false);
    const [currentQuestionPageIndex, setCurrentQuestionPageIndex] = useState(() => {
        // Load current question index from local storage when the component mounts
        const savedPageIndex = localStorage.getItem(localStoragePageIndex);
        return savedPageIndex ? parseInt(savedPageIndex) : 0;
    });
    const [answers, setAnswers] = useState(() => {
        // Load answers from local storage when the component mounts
        const savedAnswers = localStorage.getItem(localStorageAnswers);
        return savedAnswers ? JSON.parse(savedAnswers) : createEmptyAnswers(questions);
    });
    const { tr } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const currentQuestion = questions[currentQuestionPageIndex];
    const currentQuestionId = currentQuestion?.question_id;
    const currentRootAnswer = answers[currentQuestionId];
    // console.log("Answers", answers)
    // TODO: Simplify This
    // const currentAnswer = answers[questions[currentQuestionIndex]?.question_id];
    if (_.isUndefined(currentRootAnswer)) {
        console.warn("* currentAnswer is undefined YET");
        console.error("| answers", answers);
        console.error("| questions", questions);
        console.error("| currentQuestionIndex", currentQuestionPageIndex);
        // return <div>Current Answer Is Undefined</div>
    }

    if (_.isNull(currentRootAnswer)) {
        console.log("currentAnswer is null, it's not answered yet");
    }
    console.log("[Questions Container] Last Known currentAnswer State Is", currentRootAnswer)

    useEffect(() => {
        console.log("[Questions Container] useEffect [questions]", questions)
        if (_.isEmpty(questions)) {
            return;
        }
        const emptyAnswers = createEmptyAnswers(questions);
        // setAnswers(emptyAnswers);
        const savedAnswers = JSON.parse(localStorage.getItem(localStorageAnswers));
        if (!_.isNil(savedAnswers)) {
            setAnswers(savedAnswers);
        } else {
            setAnswers(emptyAnswers);
            localStorage.setItem(localStorageAnswers, JSON.stringify(emptyAnswers));
        }
        const savedPageIndex = localStorage.getItem(localStoragePageIndex);
        if (savedPageIndex) {
            setCurrentQuestionPageIndex(parseInt(savedPageIndex));
        } else {
            setCurrentQuestionPageIndex(0);
        }
        console.log("[Questions Container] useEffect [questions] setCurrentAnswer", answers, currentQuestion?.question_id, answers[currentQuestion?.question_id])
    }, [questions])

    useEffect(() => {
        console.log("[Questions Container] useEffect [currentQuestionIndex]", currentQuestionPageIndex, "answers", answers, "questions", questions, "currentAnswer", currentRootAnswer)
        if (_.isEmpty(questions)) {
            console.log("[Questions Container] useEffect [currentQuestionIndex] empty questions");
            return;
        }
        console.log("[Questions Container] useEffect [currentQuestionIndex] setCurrentAnswer", answers, currentQuestion?.question_id, answers[currentQuestion?.question_id])
    }, [currentQuestionPageIndex])

    const onAnswerChanged = (question_id: string, text: string | string[] | Date) => {
        console.log("#### SET ANSWER", question_id, text)
        setAnswers({
            ...answers,
            [question_id]: text
        });
    }

    const onPrevButtonClicked = () => {
        if (currentQuestionPageIndex > 0) {
            setCurrentQuestionPageIndex(currentQuestionPageIndex - 1)
        }
    }

    const onNextButtonClicked = () => {
        setCurrentQuestionPageIndex(currentQuestionPageIndex + 1)
    }

    const onSubmitButtonClicked = async () => {
        setIsSubmitting(true);
        if (isSpanMode) {
            const allAnsweredSpan = questions.every((q) => checkRequiredAnswers(q, answers));
            if (!allAnsweredSpan) {
                toast.warn(tr("모든 질문에 답변해주세요"));
                const unansweredQuestion = questions.find((q) => !checkRequiredAnswers(q, answers));
                console.log("UNANSWERED", unansweredQuestion);
                scrollToElm(unansweredQuestion?.question_id);
                return;
            }
        }
        submit();
    }

    const onStepClicked = (idx) => {
        console.log("Step Clicked", idx);
        const allAnswered = checkRequiredAnswers(currentQuestion, answers);
        if (!allAnswered) {
            toast.warn(tr("모든 질문에 답변해주세요"));
            return;
        }
        const maxIndex = parseInt(localStorage.getItem(localStorageMaxPageIndex)) || 0;
        if (idx > maxIndex) {
            return;
        }
        setCurrentQuestionPageIndex(idx);
    }

    const submit = async () => {
        try {
            const ok = await onSubmit(answers);
            if (ok) {
                setCurrentQuestionPageIndex(0);
            }
        } catch (e) {
            console.error(e);
            toast.error(tr("제출 중 오류가 발생했습니다. 다시 시도해주세요."));
        } finally {
            setIsSubmitting(false);
        }
    }

    useEffect(() => {
        // Save answers to local storage whenever answers state changes
        console.log("[Questions Container] useEffect[answers] set localStorage Answers", answers)
        if (_.isNil(localStorage.getItem(localStorageAnswers))) {
            console.log("DO NOT INITIALIZE THIS PLACE", answers)
            return;
        }
        localStorage.setItem(localStorageAnswers, JSON.stringify(answers));
    }, [answers]);

    useEffect(() => {
        // Save current question index to local storage whenever it changes
        localStorage.setItem(localStoragePageIndex, currentQuestionPageIndex.toString());
        const maxIndex = Math.max(currentQuestionPageIndex, parseInt(localStorage.getItem(localStorageMaxPageIndex)) || 0);
        localStorage.setItem(localStorageMaxPageIndex, maxIndex.toString());
    }, [currentQuestionPageIndex])


    if (_.isEmpty(questions)) {
        return <div>Questions Is Empty</div>
    }

    let prevIsDisabled = currentQuestionPageIndex === 0;
    let nextIsDisabled = currentQuestionPageIndex === questions.length - 1;

    console.log("[Questions Container] prevIsDisabled", prevIsDisabled, "nextIsDisabled", nextIsDisabled, "currentQuestionPageIndex", currentQuestionPageIndex, "questions.length", questions.length, "answers", answers)
    console.log("currentQuestion", currentQuestion);
    const allAnswered = checkRequiredAnswers(currentQuestion, answers);
    const allAnsweredSpan = questions.every((q) => checkRequiredAnswers(q, answers));
    console.log("allAnswered", allAnswered)
    nextIsDisabled = !allAnswered

    const maxIndex = parseInt(localStorage.getItem(localStorageMaxPageIndex)) || 0;

    return (
        <div>
            {!isSpanMode && <>
                <div className="hidden md:flex justify-center mt-3">
                    <div className="overflow-auto">
                        <Bullets
                            steps={questions.map((q) => ({ name: q.question_id, href: "#", status: "" }))}
                            currentStepIndex={currentQuestionPageIndex}
                            maxStepIndex={maxIndex}
                            onClick={onStepClicked}
                        />
                    </div>
                </div>
                <div className="md:hidden justify-center mt-3">
                    <div className="overflow-auto">
                        <Progress length={questions.length} currentIndex={currentQuestionPageIndex} />
                    </div>
                </div>
            </>}
            <div className="mx-auto max-w-3xl mt-3">
                {!isSpanMode ?
                    currentQuestion &&
                    <>
                        <QuestionContainer question={currentQuestion} currentAnswer={currentRootAnswer} onAnswerChanged={onAnswerChanged} answers={answers} questionNumber={currentQuestionPageIndex + 1}></QuestionContainer>
                        <div className="flex justify-between mt-5 mb-2">
                            <div>
                                <Button onButtonClicked={onPrevButtonClicked} disabled={prevIsDisabled}>{tr("이전")}</Button>
                            </div>
                            <div>
                                {currentQuestionPageIndex < questions.length - 1 ?
                                    <Button onButtonClicked={onNextButtonClicked} disabled={nextIsDisabled}>{tr("다음")}</Button>
                                    : <Button onButtonClicked={onSubmitButtonClicked} disabled={!allAnsweredSpan || isSubmitting}>{tr("다음")}</Button>
                                }
                            </div>
                        </div>
                    </> :
                    <>
                        {questions.map((q, idx) => (
                            <div key={q.question_id} id={q.question_id} className="mb-5">
                                <QuestionContainer question={q} currentAnswer={answers[q.question_id]} onAnswerChanged={onAnswerChanged} answers={answers} questionNumber={idx + 1}></QuestionContainer>
                            </div>
                        ))}
                        <div className="flex justify-end mt-5 mb-2">
                            <div>
                                <Button onButtonClicked={onSubmitButtonClicked} disabled={isSubmitting}>{tr("다음")}</Button>
                                {/* Do not disable all answered check Beacuse Have To Scroll To Unanswered Answer If Click*/}
                            </div>
                        </div>
                    </>
                }
                <button
                    onClick={() => {
                        setIsSpanMode(!isSpanMode);
                    }}
                    className="flex w-full h-full justify-center rounded-md py-[1.5px] font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-nowrap items-center shadow-md bg-gray-200 hover:bg-gray-300 w-full mt-3"
                >
                    <div className="">
                        {!isSpanMode ?
                            <ChevronDownIcon className="size-3" />
                            : <ChevronUpIcon className="size-3" />
                        }
                    </div>
                </button>
            </div>
        </div >
    )
}