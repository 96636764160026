import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Select from "components/Form/Select";
import { adminClient } from "apis/admin";
import _ from "lodash";
import { Intake } from "../Editor";
import SimpleTable from "components/Table/Simple";
import { useModal } from "components/Modal/Simple";
import Input from "components/Form/Input";
import Button from "components/Form/Button";
import Card from "components/Card";
import { columnInfos } from "./config";
import IconButton from "components/Form/Button/Icon";
import papagoIcon from "assets/logo/papago.png";
import PageTitle from "components/Title/Page";
import { toast } from "react-toastify";
import Textarea from "components/Form/Textarea";
import { ArrowDownOnSquareIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";

const INTAKE_TITLE_LABEL = "진료지 제목";
const INTAKE_DESCRIPTION_LABEL = "진료지 설명";

const createTableData = (originData, trs) => {
    console.log("[createTableData]", originData, trs)
    const tableData = originData.map((elm) => {
        const tr = trs.find((tr) => tr.origin === elm.origin);
        return {
            idx: elm.idx,
            origin: elm.origin,
            translated: tr?.translated ?? ""
        }
    })
    return tableData;
}

function flattenIntakeQuestions(sections) {
    let questionsFlat = [];

    function flattenQuestions(questions, prefix = "") {
        questions.forEach((question, index) => {
            const questionId = prefix ? `${prefix}-${index + 1}` : `${index + 1}`;
            questionsFlat.push({ idx: `질문${questionId}`, origin: question.text });

            // Flatten options if any
            // if (question.options) { //someday..
            if (question.type === "SINGLE_CHOICE" || question.type === "MULTIPLE_CHOICE") {
                question.options.forEach((option, optionIndex) => {
                    const optionId = `질문${questionId}의 선택지${optionIndex + 1}`;
                    questionsFlat.push({ idx: optionId, origin: option.text });
                });
            }
            // Flatten conditional questions if any
            if (question.conditional_questions) {
                flattenQuestions(question.conditional_questions, questionId);
            }
        });
    }

    sections.forEach((section, index) => {
        questionsFlat.push({ idx: `섹션${index + 1}의 제목`, origin: section.name })
        flattenQuestions(section.questions);
    });

    return questionsFlat;
}

export const TranslateForm = ({ data, intakeId = undefined, submissionId = undefined, onSave = () => { }, postUrl = `/translations/intakes`, origin_lang = undefined, translated_lang = undefined }) => {
    const [obj, setObj] = useState(data);

    const onButtonClicked = async () => {
        const type = obj.idx === INTAKE_TITLE_LABEL ? "title" : null;
        const body = {
            origin: data.origin,
            translated: obj.translated,
            ...(intakeId && { intake_id: intakeId }),
            ...(submissionId && { submission_id: submissionId }),
            ...(translated_lang && { translated_lang }),
            ...(origin_lang && { origin_lang }),
            ...(type && { type })
        }
        try {
            const ret = await adminClient.post(postUrl, body);
            console.log(ret);
            toast.success("저장되었습니다.");
            onSave();
        } catch (e) {
            toast.error("저장에 실패했습니다.");
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onButtonClicked();
        }
    };
    return (
        <div className="flex flex-col space-y-2 min-w-[80vw]" onKeyDown={handleKeyDown}>
            <h1 className="font-semibold">원문</h1>
            <div className="shadow-sm ring-1 ring-inset ring-gray-300 w-full rounded-md bg-gray-100 cursor-not-allowed w-fit">
                <div className="px-2 py-1 flex justify-between items-center">
                    <div className="sm:text-sm w-full">
                        {data.origin}
                    </div>
                    <div>
                        <IconButton
                            paddingClassName="p-[2px]"
                            classNames="align-sub"
                            onButtonClicked={() => {
                                window.open(`https://papago.naver.com/?sk=ja&tk=ko&hn=0&st=${data.origin}`, 'newWindow', 'width=600, height=600, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no')
                            }}
                        >
                            <div className="size-6">
                                <img src={papagoIcon} alt="번역" className="w-6 h-6" />
                            </div>
                        </IconButton>
                    </div>
                </div>
            </div>
            <h1 className="font-semibold">번역</h1>
            <div className="relative">
                <Textarea value={obj.translated} onChange={(text) => { setObj({ ...obj, translated: text }) }}></Textarea>
                <div className="absolute top-[0.25rem] right-[0.375rem]">
                    <IconButton paddingClassName="p-[6px]" onButtonClicked={() => { setObj({ ...obj, translated: data.origin }) }}>
                        <ArrowDownOnSquareIcon className="size-4" />
                    </IconButton>
                </div>
            </div>
            <div className="flex justify-end mt-2">
                <div className="">
                    <Button onButtonClicked={onButtonClicked}>저장</Button>
                </div>
            </div>

        </div>
    )
}

const LANG_ITEMS = [
    { name: "JP" },
    { name: "EN" },
]

const IntakeTranslatePage = () => {
    const [intakeItems, setIntakeItems] = useState([]);
    const [selectedIntake, setSelectedIntake] = useState(null);
    const { Modal: TranslateModal, openModal, closeModal } = useModal({});
    const [currentRow, setCurrentRow] = useState(null);
    const [originData, setOriginData] = useState(null);
    const [trs, setTrs] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectedLang, setSelectedLang] = useState(LANG_ITEMS[0]);

    const pathParams = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("IntakeTranslatePage")
        // get all intake data
        updateIntakeItems();
    }, [])

    useEffect(() => {
        if (_.isNil(selectedIntake)) return;
        console.log(selectedIntake)
        updateIntakeData();
        updateTrs();
        navigate(selectedIntake.intake_id);
    }, [selectedIntake]);

    useEffect(() => {
        if (_.isNil(selectedLang)) return;
        updateTrs();
    }, [selectedLang])

    const updateIntakeItems = async () => {
        // get all intake data
        const ret = await adminClient.get(`/intakes/distinct/latest`);
        const intakesRet = ret?.data;
        if (_.isNil(intakesRet)) return;
        const intakeItems = intakesRet.map((elm: Intake) => ({ name: elm.title, intake_id: elm.intake_id, clinic_id: elm.clinic_id }))
        const intakeItemsWithoutCommon = intakeItems.filter((elm) => elm.clinic_id !== "COMMON");
        setIntakeItems(intakeItemsWithoutCommon);
        const intakeIdFromUrl = pathParams.intakeId;
        if (!_.isNil(intakeIdFromUrl)) {
            const selectedIntake = intakeItems.find((elm) => elm.intake_id === intakeIdFromUrl);
            setSelectedIntake(selectedIntake);
            return;
        }
        setSelectedIntake(intakeItemsWithoutCommon[0]);
    }

    const updateIntakeData = async () => {
        // get all intake data
        const ret = await adminClient.get(`/intakes/${selectedIntake.intake_id}`);
        const tempTableData = ret?.data;
        console.log("[intake data]", tempTableData)
        if (_.isNil(tempTableData)) return;
        const falttenIntake = flattenIntakeQuestions(tempTableData.sections);
        const cleared = falttenIntake.filter((elm) => !_.isEmpty(elm.origin));
        // { idx: optionId, origin: option.text })
        const unique = _.uniqBy(cleared, "origin");
        // add title
        setOriginData([
            { idx: INTAKE_TITLE_LABEL, origin: tempTableData.title },
            { idx: INTAKE_DESCRIPTION_LABEL, origin: tempTableData.description },
            ...unique
        ]);
    }

    const onTrSaved = () => {
        updateTrs();
        closeModal();
    }

    const updateTrs = async () => {
        if (_.isNil(selectedIntake)) return;
        const intakeId = selectedIntake.intake_id;
        const params = {
            id: intakeId,
            lang: selectedLang.name
        }
        try {
            const ret = await adminClient.get(`/translations/intakes`, { params });
            console.log("[trs raw ret]", ret);
            const tempTrData = ret?.data;
            if (_.isNil(tempTrData)) return;
            setTrs(tempTrData);
        } catch (e) {
            // toast.error("번역 데이터를 가져오는데 실패했습니다.");
            console.error(e);
        }
    }

    useEffect(() => {
        if (_.isNil(originData)) return;
        if (_.isNil(trs)) return;
        const tempTableData = createTableData(originData, trs);
        setTableData(tempTableData);
    }, [originData, trs])

    console.log("[TableData]", tableData)

    const onPreviewClicked = () => {
        const url = `${process.env.REACT_APP_SITE_URL}/intake/preview/${selectedIntake.intake_id}?lang=${selectedLang.name}`;
        // const url = `http://localhost:4000/intake/preview/${selectedIntake.intake_id}?lang=${selectedLang.name}`
        window.open(url);
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>진료지 번역</PageTitle>
                <div className="flex space-x-2 justify-between overflow-auto pb-2">
                    <div className="flex space-x-2">
                        <div>
                            <Select items={intakeItems} selectedItem={selectedIntake} onChange={setSelectedIntake} saveSelectedItems={true} />
                        </div>
                        <div>
                            <Select items={LANG_ITEMS} selectedItem={selectedLang} onChange={setSelectedLang} />
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div className="">
                            <Button onButtonClicked={() => { navigate(`/admin/intake/editor/${selectedIntake.intake_id}`) }}>진료지 수정</Button>
                        </div>
                        <div className="">
                            <Button onButtonClicked={onPreviewClicked}>미리보기</Button>
                        </div>
                    </div>
                </div>
                <div>
                    <SimpleTable data={tableData} columns={columnInfos} onRowClicked={(i, row) => {
                        console.log(i, row);
                        setCurrentRow(row);
                        openModal();
                    }} />
                </div>
                <div>
                    <div className="flex mt-2">
                        <div>
                            <Button onButtonClicked={() => { navigate("/admin/intake/list") }}>진료지 목록</Button>
                        </div>
                    </div>
                </div>
                <TranslateModal>
                    <TranslateForm data={currentRow} intakeId={selectedIntake?.intake_id} translated_lang={selectedLang.name} onSave={onTrSaved}></TranslateForm>
                </TranslateModal>
            </div>
        </Card>
    )
}

export default IntakeTranslatePage;