import React, { useState } from "react";
import { useModal } from "components/Modal/Simple";
import Button from "components/Form/Button";
import Select, { SelectItem } from "components/Form/Select";

export const SimpleSelectForm = ({ externalData = null, closeModal = () => { }, resolveRef = null }) => {
    const [selectedItem, setSelectedItem] = useState(externalData?.items[0]);

    const onSubmit = () => {
        resolveRef.current(selectedItem);
        closeModal();
    }

    return (
        <div className="flex flex-col space-y-2">
            <h1 className="text-lg">{externalData?.message}</h1>
            <Select items={externalData?.items} selectedItem={selectedItem} onChange={setSelectedItem}></Select>
            <div className="flex justify-end">
                <div>
                    <Button onButtonClicked={onSubmit}>입력</Button>
                </div>
            </div>
        </div>
    )
}

export const useSelectDialog = () => {
    const { Modal, openModalSync } = useModal();

    const openDialog = async (message, items): Promise<SelectItem> => {
        return await openModalSync({ message, items });
    }

    return {
        DialogProvider: () =>
            <Modal>
                <SimpleSelectForm />
            </Modal>,
        openDialog
    }

}