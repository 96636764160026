import React, { useEffect, useState } from "react";
import axios from "axios";
import { CustomerState } from "pages/Admin/Customer/Status/config";
import SimpleTable from "components/Table/Simple";
import { columnInfos } from "./config";
import Card from "components/Card";
// import { memberClient } from "apis/member";
import { doctorClient } from "apis/doctor";
import PageTitle from "components/Title/Page";

const DoctorPrescriptionPage = () => {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        fetchSubmissions();
    }, [])

    const fetchSubmissions = async () => {
        try {
            const params = {
                page: 1,
                limit: 1000
            }
            const ret = await doctorClient.get(`/public/trackings`, { params });
            const rawData = ret.data.data;
            const filteredData = rawData.filter((elm) => [CustomerState.AWAITING_PRESCRIPTION].includes(CustomerState[elm.state]));
            console.log(filteredData);
            setTableData(filteredData);
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>처방전 작성</PageTitle>
                <SimpleTable data={tableData} columns={columnInfos}></SimpleTable>
            </div>
        </Card>
    )
}

export default DoctorPrescriptionPage;
