import React, { useState } from "react";
import GeneralTaskList from "containers/Task/GeneralTaskList";
import { columnInfos } from "containers/Task/GeneralTaskList/config";
import { CustomerState } from "pages/Admin/Customer/Status/config";
import { useNavigate } from "react-router-dom";
import Card from "components/Card";
import PageTitle from "components/Title/Page";
import IconButton from "components/Form/Button/Icon";
import { useInputDialog } from "components/Dialog/Input";
import { toast } from "react-toastify";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { adminClient } from "apis/admin";
import { randomString } from "utils/math";
import Button from "components/Form/Button";
import { useModal } from "components/Modal/Simple";
import TranslateSubmissionStatViewer from "./StatViewer";

const TranslateSubmission = () => {
    const states: (keyof typeof CustomerState)[] = ["AWAITING_SUBMISSION_TRANSLATION"]
    const { DialogProvider, openDialog } = useInputDialog();
    const { Modal: StatViewerModal, openModal: openStatViewerModal } = useModal();
    const [refreshKey, setRefreshKey] = useState(randomString());

    const navigate = useNavigate();

    const columnInfosWithTask = [
        {
            field: "task",
            label: "작업",
            visible: true,
            render: (value, field, row) =>
                <IconButton
                    paddingClassName="px-2 py-1"
                    onButtonClicked={() => {
                        navigate(`/admin/task/translate-submission/editor/${row.submission_id}`)
                    }}
                >
                    <div className="text-xs">
                        응답지 번역
                    </div>
                </IconButton>,
            align: "center"
        },
        ...columnInfos.map((info) => {
            if ([
                "clinic_user_id",
                "awaiting_at",
                "purchase_at",
                "shipping_address",
                "deliver_request_at",
                "shipping_company",
                "tracking_number",
                "tracking_shipping_link",
                "deliver_at",
                "delivered_at",
                "submission_translated_at",
            ].includes(info.field)) {
                return {
                    ...info,
                    visible: false
                }
            }
            if (info.field === "nudge_memo") {
                return {
                    ...info,
                    visible: true,
                    render: (value, field, row) => {
                        return (
                            <div className="flex space-x-2 items-center cursor-pointer" onClick={async () => {
                                try {
                                    const result = await openDialog("메모 입력", value);
                                    console.log("[result]", result);
                                    if (_.isNil(result)) {
                                        return;
                                    }
                                    const ret = await adminClient.patch(`/trackings/${row.submission_id}/field`, {
                                        nudge_memo: result
                                    });
                                    console.log("[nudge_memo ret]", ret);
                                    toast.success("확인중 메모가 수정되었습니다.");
                                    setRefreshKey(randomString());
                                } catch (e) {
                                    console.error(e);
                                    toast.error("확인중 메모 변경에 실패했습니다.");
                                }
                            }}>
                                <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                                <div>{value}</div>
                            </div>
                        )
                    }
                }
            }
            return info;
        })
    ]

    return (
        <Card>
            <div className="p-3">
                <PageTitle>응답지 번역</PageTitle>
                <GeneralTaskList states={states} columnInfos={columnInfosWithTask} randomKeyExternal={refreshKey}>
                    <Button onButtonClicked={openStatViewerModal}>번역건수 확인</Button>
                </GeneralTaskList>
            </div>
            <DialogProvider />
            <StatViewerModal>
                <TranslateSubmissionStatViewer/>
            </StatViewerModal>
        </Card>
    )
}

export default TranslateSubmission;
