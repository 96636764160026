import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { adminClient } from 'apis/admin';
import Textarea from 'components/Form/Textarea';
import Button from 'components/Form/Button';
import Card from 'components/Card';
import { assembleQuillContentsWithFields, isEmptyOps } from 'pages/Admin/Clinic/PrescriptionTemplate/Editor/helper';
import Quill from 'quill';
import QuillMadeArticle from 'components/Article/QuillMade';
import VariableWriteForm, { NAME_LIKE_FIELDS } from 'pages/Doctor/Prescrtiption/Editor/VariableWriteForm';
import FieldsViewer from './FieldsViewer';
import { useModal } from 'components/Modal/Simple';
import SimpleTable from 'components/Table/Simple';
import { fetchAndCreateSubmissionDetailTableData, parseSubmissionDetailTableData } from 'pages/Admin/Customer/Status/DetailViewer';
import { columnInfos as submissionColumnInfos } from 'pages/Admin/Task/TranslateSubmission/Editor/config';
import { toast } from 'react-toastify';

const TranslatePrescriptionEditor = () => {
    const { submissionId } = useParams();
    const [translated, setTranslated] = useState('');
    const navigate = useNavigate();
    const [originQuill] = useState(new Quill(document.createElement('div')));
    const [transQuill] = useState(new Quill(document.createElement('div')));
    const [originPrescriptionHtml, setOriginPrescriptionHtml] = useState('');
    const [prescriptionDetail, setPrescriptionDetail] = useState(null);
    const [translatedPrescriptionHtml, setTranslatedPrescriptionHtml] = useState('');
    const [translatedFields, setTranslatedFields] = useState([]);
    const [translatedContents, setTranslatedContents] = useState([]);
    const [initialFormData, setInitialFormData] = useState({});
    const { Modal: SubmissionModal, openModal: openSubmissionModal, closeModal: closeSubmissionModal } = useModal({});
    const [submissionTableDataBySection, setSubmissionTableDataBySection] = useState([]);

    useEffect(() => {
        if (_.isNil(submissionId) || _.isEmpty(submissionId)) {
            navigate("/admin/customer/status");
        }
    }, [])

    useEffect(() => {
        fetchPrescription();
    }, [])

    useEffect(() => {
        console.log("[TranslatePrescriptionEditor] prescriptionDetail", prescriptionDetail);
        if (_.isNil(prescriptionDetail)) {
            return;
        }
        const { fields, contents, translated_contents } = prescriptionDetail;
        console.log("[TranslatePrescriptionEditor] fields", fields)
        // find nameLikeField
        const formData = {};
        fields.forEach((field) => {
            formData[field.key] = field.translated_data;
        })
        setInitialFormData(formData);
    }, [prescriptionDetail])

    const fetchPrescription = async () => {
        const ret = await adminClient.get(`/prescriptions/${submissionId}/details`);
        console.log("PRESCRIPTION DETAIL", ret);
        setPrescriptionDetail(ret.data);
        const { fields, contents, translated_contents } = ret.data;
        console.log("FIELDS", fields)
        console.log("CONTENTS", contents)
        const assembledContents = assembleQuillContentsWithFields(contents, fields, false);
        console.log("ASSEMBLED CONTENTS", assembledContents)
        originQuill.setContents(assembledContents);
        setOriginPrescriptionHtml(originQuill.getSemanticHTML());
        setTranslatedFields(fields);
        setTranslatedContents(translated_contents);
        createAndUpdateHtmlPreview(fields, translated_contents);
    }

    const onSave = async () => {
        if (translatedFields.some(field => field.translated_data === "" || isEmptyOps(field.translated_data))) {
            toast.warn("모든 변수를 입력해주세요.");
            return false;
        };
        const data = {
            submission_id: submissionId,
            prescription_template_id: prescriptionDetail.prescription_template_id,
            prescription_template_version: prescriptionDetail.prescription_template_version,
            prescription_template_name: prescriptionDetail.prescription_template_name,
            fields: translatedFields
        }
        console.log("[DoctorPrescriptionWritePage] onComplete data", data);
        // put
        try {
            const ret = await adminClient.put(`/prescriptions/${submissionId}`, data);
            console.log(ret);
            toast.success("처방전 번역 저장이 완료되었습니다.");
            return true;
        } catch (e) {
            console.error(e);
            toast.error("처방전 번역 저장에 실패했습니다.");
            return false;
        }
    }

    const onComplete = async () => {
        const comfirmOk = window.confirm("처방전 번역을 완료하시겠습니까?");
        if(!comfirmOk) {
            return;
        }
        const ok = await onSave();
        if (!ok) {
            return;
        }
        try {
            const ret = await adminClient.patch(`/trackings/${submissionId}/awaiting_customer_noti`);
            console.log(ret);
            toast.success("처방전 번역 상태변경이 완료되었습니다.");
        } catch (e) {
            console.error(e);
            toast.error("처방전 번역 상태변경에 실패했습니다.");
        }
        navigate(-1);
    }

    const onFormDataChanged = (data) => {
        console.log("[DoctorPrescriptionWritePage] onFormDataChanged data", data);
        const newFields = translatedFields.map((field) => {
            const { key } = field;
            return {
                ...field,
                translated_data: data[key]
            }
        })
        setTranslatedFields(newFields);
        createAndUpdateHtmlPreview(newFields, translatedContents);
    }

    const createAndUpdateHtmlPreview = (fields, contents) => {
        console.log("[DoctorPrescriptionWritePage] onFormDataChanged fields, contents", fields, contents);
        const newContents = assembleQuillContentsWithFields(contents, fields, true);
        console.log("[DoctorPrescriptionWritePage] onFormDataChanged newContents", newContents);
        transQuill.setContents(newContents);
        setTranslatedPrescriptionHtml(transQuill.getSemanticHTML());
    }

    const onSubmissionViewRequested = async () => {
        // const td = await fetchAndCreateSubmissionDetailTableData(submissionId);
        const ret = await adminClient.get(`/submissions/${submissionId}/details`, { params: { lang: "jp" } });
        const td = parseSubmissionDetailTableData(ret.data);
        if(_.isEmpty(td)) {
            return;
        }
        setSubmissionTableDataBySection(td);
        openSubmissionModal();
    }

    return (
        <>
            <Card>
                <div className="p-2 flex flex-col space-y-2">
                    <div className="grid lg:grid-cols-2">
                        <div className="p-2 lg:border-r">
                            <div>
                                <div className="text-xl font-bold">처방전 변수</div>
                                <Card>
                                    <div className="p-2">
                                        <FieldsViewer fields={prescriptionDetail?.fields}></FieldsViewer>
                                    </div>
                                </Card>
                            </div>
                            <div>
                                <div className="text-xl font-bold">처방전 원문</div>
                                <Card>
                                    <div className="px-2 py-1">
                                        <QuillMadeArticle htmlText={originPrescriptionHtml}></QuillMadeArticle>
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <div className="p-2">
                            <div>
                                <div className="text-xl font-bold">처방전 변수 번역</div>
                                <Card>
                                    <div className="p-2">
                                        <VariableWriteForm
                                            initialFormData={initialFormData}
                                            templateFields={prescriptionDetail?.template_fields}
                                            dataFields={prescriptionDetail?.fields}
                                            isForeignExample={true}
                                            onDataChanged={onFormDataChanged}
                                        />
                                    </div>
                                </Card>
                            </div>
                            <div>
                                <div className="text-xl font-bold">번역본 미리보기</div>
                                <Card>
                                    <div className="px-2 py-1">
                                        <QuillMadeArticle htmlText={translatedPrescriptionHtml}></QuillMadeArticle>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div>
                            <Button onButtonClicked={onSubmissionViewRequested}>응답지 보기</Button>
                        </div>
                        <div className="flex space-x-2">
                            <div>
                                <Button onButtonClicked={() => { navigate(-1) }}>뒤로가기</Button>
                            </div>
                            <div>
                                <Button onButtonClicked={onSave}>저장</Button>
                            </div>
                            <div>
                                <Button onButtonClicked={onComplete}>완료</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
            <SubmissionModal>
                <div>
                    {submissionTableDataBySection.map((section) => (
                        <div key={section.section_id}>
                            <h2 className="text-base font-semibold">{section.section_title}</h2>
                            <SimpleTable data={section.data} columns={submissionColumnInfos}></SimpleTable>
                        </div>
                    ))}
                </div>
            </SubmissionModal>
        </>


    )
}

export default TranslatePrescriptionEditor;
