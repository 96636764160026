import { Column } from "components/Table/Simple";
import { formatDate } from "components/Table/Simple/presets";
import { PAYMENT_METHOD_ITEMS } from "pages/Admin/Task/InputPurchaseInfo/Editor/config";

/*
submission_id
user_id
clinic_id
purchase_at
payment_method
payment_month
financial_institution
currency
financial_fee
memo
shipping_cost
shipping_address
discount
point
items
created_at
updated_at
*/
export const columnInfos: Column[] = [
	{
		field: "purchase_at",
		label: "구매일",
		visible: true,
		render: formatDate
	},
	{
		field: "clinic_id",
		label: "의원ID",
		visible: true,
	},
	{
		field: "clinic_user_id",
		label: "고객번호",
		visible: true,
	},
	{
		field: "user_name",
		label: "고객명",
		visible: true,
	},
	{
		field: "assigned",
		label: "담당자",
		visible: true,
	},
	{
		field: "submission_id",
		label: "응답지번호",
		visible: true,
	},
	{
		field: "user_id",
		label: "사용자ID",
		visible: true,
	},
	{
		field: "items",
		label: "구매제품",
		visible: true,
	},
	{
		field: "total_product_price",
		label: "제품가격",
		visible: true,
		render: (value, field, row) => {
			let totalPrice = 0;
			row?.items?.forEach((item) => {
				totalPrice += item.price;
			});
			return totalPrice;
		}
	},
	{
		field: "discount",
		label: "할인",
		visible: true,
		render: (value, field, row) => {
			let discount = 0;
			row?.items?.forEach((item) => {
				discount += item.discount;
			});
			return discount;
		}
	},
	{
		field: "total_product_price_after_discount",
		label: "할인 후 제품가격",
		visible: true,
		render: (value, field, row) => {
			let totalPrice = 0;
			row?.items?.forEach((item) => {
				totalPrice += item.price - item.discount;
			});
			return totalPrice;
		}
	},
	{
		field: "financial_fee",
		label: "결제수수료",
		visible: true,
	},
	{
		field: "shipping_cost",
		label: "배송비",
		visible: true,
	},
	{
		field: "used_point",
		label: "사용포인트",
		visible: true,
	},
	{
		field: "total_price",
		label: "총 구매가격",
		visible: true,
		render: (value, field, row) => {
			let totalPrice = 0;
			row?.items?.forEach((item) => {
				totalPrice += item.price - item.discount;
			});
			return totalPrice + row.financial_fee + row.shipping_cost - row.used_point;
		}
	},
	{
		field: "earned_point",
		label: "적립포인트",
		visible: true,
	},
	{
		field: "payment_method",
		label: "결제수단",
		visible: true,
		render: (value) => {
			const found = PAYMENT_METHOD_ITEMS.find((item) => item.value === value);
			if (found) {
				return found.name;
			}
			return value;
		}
	},
	{
		field: "currency",
		label: "통화",
		visible: true,
	},
	{
		field: "financial_fee_type",
		label: "결제수수료 유형",
		visible: false
	},

	{
		field: "memo",
		label: "메모",
		visible: true,
	},
	{
		field: "note",
		label: "비고",
		visible: true,
	},
	{
		field: "shipping_address",
		label: "배송지",
		visible: true,
	},
	{
		field: "created_at",
		label: "생성일",
		visible: false,
	},
	{
		field: "updated_at",
		label: "수정일",
		visible: false,
	},
	{
		field: "aftercare_type_id",
		label: "복용후 관리타입",
		visible: false,
	},
	{
		field: "refund",
		label: "환불정보",
		visible: true,
	},
	{
		field: "refund_amount",
		label: "환불금액",
		visible: false,
	},
	{
		field: "refund_date",
		label: "환불일",
		render: formatDate,
		visible: false,
	},
	{
		field: "refund_reason",
		label: "환불사유",
		visible: false
	},
	{
		field: "state",
		label: "상태",
		visible: false,
	}
]