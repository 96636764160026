
import React from "react";
import { Routes, Route } from "react-router-dom";

import DoctorStatusPage from "pages/Doctor/Status";
import DoctorPrescriptionPage from "pages/Doctor/Prescrtiption";
import DoctorPrescriptionEditorPage from "pages/Doctor/Prescrtiption/Editor";
import DoctorSalesPage from "pages/Doctor/Sales";

export default () => (
    <Routes>
        <Route path="/status" element={<DoctorStatusPage />}></Route>
        <Route path="/prescription" element={<DoctorPrescriptionPage />}></Route>
        <Route path="/prescription/write" element={<DoctorPrescriptionEditorPage />}>
            <Route path=":submissionId" element={<DoctorPrescriptionEditorPage />}></Route>
        </Route>
        <Route path="/sales" element={<DoctorSalesPage />}></Route>
    </Routes>
)

